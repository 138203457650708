import format from 'format-number';
import appGlobalConfig from 'appGlobalConfig';
import formatStringByPattern from 'format-string-by-pattern'

function roundTo (number, float) {
  if(number === 0) return ""
  return parseFloat(number).toFixed(float)
}

function formatNumber(number) {
  if(number > 0){
    return "+" + number
  }else{
    return number
  }
}

const agmUtils = {

  
  getDate30DRange: function(){

    let date = new Date();
    date.setHours(0, 0, 0, 0);

    var today = new Date();
    today.setHours(24, 0, 0, 0);
    var firstDay = new Date(today)
    firstDay.setDate(today.getDate() - 30);
    let fromDate = firstDay

    return {fromDate, today}

  },

  
  roundToFormat: function(number, float) {
    if(number === 0) return ""
    return formatNumber(parseFloat(number).toFixed(float))
  },


  toPercent: function(number, float){
    if(number === 0) return "0.00%";
    if(number === undefined) return  "0.00%";
    var percent = parseFloat(number * 100).toFixed(float) + "%";
    return percent;
  },

  getAvatarSource: function(exchange){
    let avatarUrl = ""
    if (exchange === "poloniex") {
      avatarUrl = '/images/avatars/poloniex.png'
    }
    if (exchange === "bitfinex") {
      avatarUrl = '/images/avatars/bitfinex.png'
    }
    return avatarUrl
  },

  getAnnualRateByMonth: function(monthlyrate) {
    if(monthlyrate === null){
      return 0.00
    }
    let annRate = roundTo((Math.pow((1+monthlyrate), 12) -1) * 100, 2)
    return parseFloat(annRate)
  },
  
  getAnnualRate: function (dailyRate) {
    if(dailyRate === null){
      return 0.00
    }
    let annFrr = roundTo((Math.pow((1+dailyRate), 365) -1) * 100, 2)
    return parseFloat(annFrr)
  },

  formatNumber: function(x) {
    return format({prefix: '', suffix: ''})(x)
  },
  
  numberWithCommas: function(x) {
    return format({prefix: appGlobalConfig.BaseCurrency, suffix: ''})(roundTo(x,2))
  },

  formatStringByPattern: function(x) {
    return formatStringByPattern('999999-99-9999', x);

  },
  
  roundTo: function(number, float) {
    return roundTo(number, float)
  },
  
  
  compareValues: function(key, order = 'asc') {
        return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // property doesn't exist on either object
          return 0;
        }
    
        const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key];
    
        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        );
      };
  }


}

export default agmUtils;
