import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import palette from "theme/palette";
import moment from 'moment';
import RegisterVotes from '../RegisterVotes/RegisterVotes'
import agmUtils  from "agmUtils"
import PopupMessage from '../PopupMessage/PopupMessage'
import firebase from "firebase/app";
import { useTranslation } from 'react-i18next'
import Alert from '@material-ui/lab/Alert';
import { useAuth } from "use-auth.js";
import {
  Card,
  CardContent,
  Button,
  Typography,
  CardHeader,
  Table,
  TableRow,
  TableCell,
  Grid,
  CardActions,
  TableBody,
  DialogContentText,
  LinearProgress,
  Tooltip
} from '@material-ui/core';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1,1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1,1)
    },
  },
  grid: {
    alignItems:"stretch"
 },
 texttitle: {
   color: palette.text.white
 },
 primaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.primary.main,
  },
  secondaryHeading: {
    color: theme.palette.success.main,
  },
  positiveNum: {
    color: theme.palette.primary.main
  },
  negativeNum: {
    color: theme.palette.warning.main
  },
 drawer: {
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
},
drawerPaper: {
  width: drawerWidth,
},
menuButton: {
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
},
toolbar: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  padding: theme.spacing(3,3),
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(1,1)
  },
  minHeight: 600
},
}));

const MyEventList  = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { user } = useAuth()
  let { uid: userId } = user || {}
  const [attendances, setAttendances] = useState([])
  const refregister = useRef(null);
  const [loading, setLoading] = React.useState(false);
  const ref = useRef(null);
  const { t } = useTranslation()
  
  firebase.auth().onAuthStateChanged(function(myuser) {
    if (!myuser) {
        props.history.replace('/sign-in')
        return

    }else{
      if (!myuser.emailVerified) {
        props.history.replace('/sign-in')
        return
      }
    }
    userId = myuser.uid 
  });

  let mydate = new Date();
  mydate.setHours(0, 0, 0, 0);

  useEffect(() => {
    
    if (userId)
    {
      const unsubscribe = firebase
      .firestore()
      .collection('agmuserattendance')
      .where('eventdate', '>=', mydate)
      .where('userid', '==', userId)
      //.where('attend', '==', true)
      .onSnapshot((snapshot) => {
        const newValues = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        
        setAttendances(newValues);
      })
      return () => { unsubscribe() }
    }
  }, [userId])

  const getdisplayeventname = (att) => {
    return att.proxyuser ? att.eventname + " (I acted as proxy in this event)": att.eventname
  }

  const getToolTips = (att) => {
    
    let msg = ""
    if(att.proxyuser){
      msg += "You are proxy user in this event"
    }
    return msg
  }

  const handleClickAttDelete = (att) => event => {
    event.preventDefault();
    
    if(att.status === "confirmed"){
      alert("Attendance already been confirmed. You can't cancel it. Please contact admin")
      return
    }

    setLoading(true)

    firebase.firestore().collection("agmuserattendance").doc(att.id).delete()
    .then(function () {
      ref.current.handleOpen(t('common.msg.successupdate'), "info");
      setLoading(false)
    })
    .catch(function(error) {
      console.log(error)
      ref.current.handleOpen(error, "error");
      setLoading(false)
  });
    
};

const getProgress= () =>{
    
  if(!loading) return ""

  return (
      <DialogContentText >
            <LinearProgress 
              color="secondary" 
              size={50}
              thickness={4}
            />
          </DialogContentText>
  )
    
}

const isVotingTimeOut = (votingTimeOut) => {
  
  const start = Date.now();
  const timeout = moment(new Date(votingTimeOut.seconds * 1000))
  return start > timeout 
}

const getDisplayMessage = (att) => {

  if(att.voted){
    return (
      <Alert severity="warning">You already voted for this event. Please check your mailbox for the webex link notification</Alert> 
    )
  }
  if(att.assignproxy){
    return (
      <Alert severity="success">You've assigned proxy for this event. You are not able to vote</Alert> 
    )
  }
  if(isVotingTimeOut(att.votingTimeOut)){
    return (
      <Alert severity="error">Voting Time Out</Alert> 
    )  
  }
  if(att.status === "rejected"){
    return (
      <Alert severity="error">
        <p>Your attendance is not approved after the verification</p>
        <p>Please ensure you have uploaded correct NRIC and number of shares</p>
        
      </Alert> 
    )  
  }
  if(att.status === "unconfirmed"){
    return (
      <Alert severity="warning">
        <p>You will not be able to vote until admin verify your shares and NRIC</p>
        <p>Please ensure you have uploaded correct NRIC and number of shares</p>
      </Alert> 
    )  
  }
  if(att.status === "confirmed" && att.prevShares != undefined){
    return (
      <div><Alert severity="success"><p>You have been verified. Admin will send you webex link for registration soon. Please check your mailbox. </p>
      </Alert> 
      <Alert severity="info">
      <p>Admin updated your shares from {att.prevShares} to {att.shares} after verification from registrar </p>
      </Alert></div> 
  )  
  }
  return (
      <Alert severity="success"><p>You have been verified</p> 
      <p>Admin will send you webex link for registration soon. Please check your mailbox. </p></Alert> 
  )
}

  const displayAttendance = () => {
    
    if(attendances === undefined) return ""

    return (
      attendances.map(att => (
        
        <Grid
          item
          lg={6}
          md={12}
          xl={6}
          xs={12}
          key={att.id}
        > 
        <Card>
          {getProgress()}
          <Tooltip title={getToolTips(att)} arrow={true}>
          <CardHeader
            className={classes.primaryHeading}
            //subheader= {comp.registration}
            title={getdisplayeventname(att)}
            //titleTypographyProps={{variant:'h5' }}
            subheaderTypographyProps={{variant:'body1' }}
          />
          </Tooltip>
         
            <CardContent>
              <Table size="small">
                <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Event Date:</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {moment(new Date(att.eventdate.seconds * 1000)).format('ll')}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Number of Shares Held:</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {agmUtils.formatNumber(att.shares)}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Status</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={att.status === "rejected"? classes.negativeNum: classes.positiveNum}>
                      {att.status.toUpperCase()}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Voted</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {att.voted ? "Yes": "No"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>I acted as proxy</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {att.proxyuser ? "Yes": "No"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Attend</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {att.attend ? "Yes": "No"}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>I assigned Proxy</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                    <span>{att.assignproxy ? "Yes - ": "No - "}</span>
                    <span>{att.assignproxy ? att.proxyemail: "N/A"}</span>
                    </Typography>
                  </TableCell>
                </TableRow>
                </TableBody>
              </Table>
          </CardContent>
          <CardContent>
            {getDisplayMessage(att)}
          </CardContent>
          <CardActions>
              <Button
                fullWidth
                size="medium"
                color="primary"
                variant="contained"
                disabled={att.assignproxy || !att.attend || att.voted || att.status === "unconfirmed" || att.status === "rejected" || isVotingTimeOut(att.votingTimeOut)}
                onClick={handleClickRegisterVotes(att)}
                //startIcon={<HttpsIcon />}
              >
                Vote
              </Button>
              <Button
                fullWidth
                size="medium"
                color="primary"
                variant="contained"
                //disabled={att.assignproxy || !att.attend || att.voted || att.status === "unconfirmed" || isVotingTimeOut(att.votingTimeOut)}
                disabled={att.voted === undefined || att.status === "unconfirmed" || att.status === "rejected"}
                onClick={handleClickViewVotes(att)}
                //startIcon={<HttpsIcon />}
              >
                View
              </Button>
              <Button
                fullWidth
                size="medium"
                color="primary"
                variant="contained"
                disabled={att.status === "confirmed"}
                onClick={handleClickAttDelete(att)}
                //startIcon={<HttpsIcon />}
              >
                Delete
              </Button>
          </CardActions>

      </Card>
    </Grid>
        )) 
    )    
  }

  const handleClickRegisterVotes = (att) => event => {
    refregister.current.handleOpen(att, false);
  }
  
  const handleClickViewVotes = (att) => event => {
    refregister.current.handleOpen(att, true);
  }

  return (
    <div className={classes.root}>
       <main className={classes.content}>
       <PopupMessage ref={ref} />
       <RegisterVotes
          ref={refregister} 
          attendances={attendances}
        />
       <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
          <Card>
          <CardHeader
            className={classes.primaryHeading}
            title={"My Registered AGM Events"}
            subheaderTypographyProps={{variant:'body1' }}
          />
          </Card>
        </Grid>
         
          {displayAttendance()}
        </Grid>
        </main>
    </div>
  );
};

export default MyEventList;
