
// const appGlobalConfig = {
//   freePlanServer: "hashbridge_vps",
//   premiumPlanServer: "hashbridge_vps",
//   basicPlanServer: "hashbridge_vps",
//   platinumPlanServer: "hashbridge_vps",
//   BaseCurrency: "USD",
//   paypalBasicPlanId: "KK9UQ83JZX5RC",
//   paypalPremiumPlanId: "47WJ4HTTZ6L5W",
//   monarchBasicPlanId: "132",
//   monarchPremiumPlanId: "133",
//   paypalGatewayURL: "https://www.sandbox.paypal.com/cgi-bin/webscr",
//   paypalGatewayImageURL: "https://www.sandbox.paypal.com/en_US/JP/i/btn/btn_subscribeCC_LG.gif",
//   monarchGatewayURL: "https://pay.monarchwallet.com/sub/",
//   verifyBitfinexURL: "https://us-central1-cryptolending-7b810.cloudfunctions.net/exchangeBitfinexKey",
//   verifyPoloniexURL: "https://us-central1-cryptolending-7b810.cloudfunctions.net/exchangePoloniexKey",
//   testLineURL: "https://us-central1-cryptolending-7b810.cloudfunctions.net/lineMessage",
//   FunctionURL: "https://us-central1-cryptolending-7b810.cloudfunctions.net/",
//   actionCodeSettingsURL : "https://cryptolending-7b810.firebaseapp.com/sign-in",
//   compoundURL : "https://api.compound.finance/api/v2/ctoken",
//   demoUser : "demo@wininnovation.net"
// };


const appGlobalConfig = {
  BaseCurrency: "USD",
  actionCodeSettingsURL : "http://agmweb-app.web.app/sign-in"
};


export default appGlobalConfig;