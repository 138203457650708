import React, { useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import { useAuth } from "use-auth.js";
import Circular from 'views/Circular/Circular'
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  whiteColor: {
    color: "white"
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const refCircular = useRef(null);
  
  const auth = useAuth()
  const handleClick = event => {
    refCircular.current.handleOpen(true)
    auth.signout();
  }

  const handleClickHome = event => {
    props.history.replace('/home')
  }

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Circular ref={refCircular} />
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/avatars/agmonline.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <Tooltip title="Home" arrow='true'>
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={handleClickHome}
            >
              <HomeIcon />
            </IconButton>
          </Tooltip>
          {/* </Link> */}
          <Tooltip title="Logout" arrow='true'>
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={handleClick}
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

//export default Topbar;
export default withRouter(Topbar);
