import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import palette from "theme/palette";
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import agmUtils  from "agmUtils"

import {
  Card,
  CardContent,
  Button,
  Typography,
  CardHeader,
  TableBody,
  Table,
  TableRow,
  TableCell,
  TextField,
  Grid,
  TableHead,
  DialogContent,
  Dialog,
  DialogActions,
  ButtonGroup
} from '@material-ui/core';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1,1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1,1)
    },
  },
  grid: {
    alignItems:"stretch"
 },
 texttitle: {
   color: palette.text.white
 },
 drawer: {
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
},
drawerPaper: {
  width: drawerWidth,
},
menuButton: {
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
},
toolbar: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  padding: theme.spacing(3,3),
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(1,1)
  },
  minHeight: 600
},
tableRightBorder : { 
  borderWidth: 1, 
  borderColor: 'gray', 
  borderStyle: 'solid',
  alignItems:"stretch",
  textAlign: 'right',
  width: 80
},
tableLeftBorder : { 
  borderWidth: 1, 
  borderColor: 'gray', 
  borderStyle: 'solid',
  alignItems:"stretch",
  textAlign: 'left',
},
tableCenterBorder : { 
  borderWidth: 1, 
  borderColor: 'gray', 
  borderStyle: 'solid',
  alignItems:"stretch",
  textAlign: 'center',
  width: 100
} // or borderTop: '1px solid red'

}));

const ViewVotings  = props => {
  const classes = useStyles();
  const { user } = useAuth()
  let { uid: userId } = user || {}
  const [companyEvents, setCompanyEvents] = React.useState([]);
  const [votings, setVotings] = React.useState([]);
  const [agmSelectedEvent, setAgmSelectedEvent] = useState([])
  const [agmEvent, setAgmEvent] = useState([])
  const [voteDetails, setVoteDetails] = useState([])
  const [resolutionsTitle, setResolutionsTitle] = useState([])
  const [openAdd, setOpenAdd] = React.useState(false);

  firebase.auth().onAuthStateChanged(function(myuser) {
    if (!myuser) {
        props.history.replace('/sign-in')
        return

    }else{
      if (!myuser.emailVerified) {
        props.history.replace('/sign-in')
        return
      }
    }
    userId = myuser.uid 
  });
  
  useEffect(() => {
      
    if(userId){

      const unsubscribe = firebase
          .firestore()
          .collection('agmusers')
          .doc(userId).onSnapshot(doc => {
          //setValues(doc.data())
          //let companyid = doc.data().plcid
          let usertype = doc.data().type
          //setCompanyid(companyid)
          if (usertype !== "admin") {
            props.history.replace('/companies')
            return
          }
        })
      return () => { unsubscribe() }
    }
    
  }, [userId])

  useEffect(() => {
    
      if(userId === undefined) return

      let mydate = new Date();
      mydate.setHours(0, 0, 0, 0);
    
      const unsubscribe = firebase
      .firestore()
      .collection('agmevents')
      .where('userid', '==', userId)
      .onSnapshot((snapshot) => {
        const newValues = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        setCompanyEvents(newValues);
      })
      return () => { unsubscribe() }

  }, [userId])



  const getVotesCount = (key, type) => {

    let selectedresol = votings.find(x=>x.key === key)
    if(type === "y") return selectedresol?.voteyescount
    if(type === "n") return selectedresol?.votenocount
  }

  const getVotesNoOfVoter = (key, type) => {

    let selectedresol = votings.find(x=>x.key === key)
    if(type === "y") return selectedresol?.yesnumber
    if(type === "n") return selectedresol?.nonumber
  }

  const getVotesPercentage = (key, type) => {

    let selectedresol = votings.find(x=>x.key === key)
    if(type === "y") return agmUtils.toPercent(selectedresol?.yespercentage || 0 , 2)
    if(type === "n") return agmUtils.toPercent(selectedresol?.nopercentage || 0 , 2)
    if(type === "all") return agmUtils.toPercent(selectedresol?.totalPercentage || 0 , 2)
  }

  const getTotalVotesShares = (key) => {

    let selectedresol = votings.find(x=>x.key === key)
    return selectedresol?.totalVoteShares
  }

  const getTotalVoter = (key) => {

    let selectedresol = votings.find(x=>x.key === key)
    return selectedresol?.numberofvoter
  }

  const displayVotingSummary = () => {
    
    if(agmEvent === undefined) return ""
    if(agmEvent.length === 0) return ""
    return (
      
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
        <Card
        >
          <CardContent>
              <Typography variant="h4" className={classes.positiveNum}>
                {agmEvent.companyname}
              </Typography>
              <Typography variant="body1" className={classes.positiveNum}>
                {agmEvent.companyaddress}
              </Typography>
              <Typography variant="body1" className={classes.positiveNum}>
                {agmEvent.companyemail}
              </Typography>
              <Typography variant="body1" className={classes.positiveNum}>
                {agmEvent.companytelno}
              </Typography>
          </CardContent>

          <CardHeader
            className={classes.primaryHeading}
            //subheader= {agmEvent.companyaddress}
            title={agmEvent.eventname + " Summary"}
            titleTypographyProps={{variant:'h4' }}
            subheaderTypographyProps={{variant:'body1' }}
          />
            <CardContent>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCenterBorder}>
                      <Typography variant="body2">
                        Resolutions
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableRightBorder}>
                      <Typography variant="body2">
                        No of Shares (Voted For)
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableRightBorder}>
                      <Typography variant="body2">
                        % (Voted For)
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableRightBorder}>
                      <Typography variant="body2">
                       No of P/S (Voted For)
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableRightBorder}>
                      <Typography variant="body2">
                        No of Shares (Voted Against)
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableRightBorder}>
                      <Typography variant="body2">
                        % (Voted Against)
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableRightBorder}>
                      <Typography variant="body2">
                       No of P/S (Voted Against)
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableRightBorder}>
                      <Typography variant="body2">
                        No of Shares (Total Votes)
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableRightBorder}>
                      <Typography variant="body2">
                        % (Total Votes)
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableRightBorder}>
                      <Typography variant="body2">
                       No of P/S (Total Votes)
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {
                  Object.keys(agmEvent.resolutions).map((key, i) => (
                    <TableRow key={key}>
                      <TableCell className={classes.tableCenterBorder}>
                        <Typography variant="body2">
                          {key?.toUpperCase()}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableRightBorder}>
                        <Typography variant="body2">
                          {agmUtils.formatNumber(getVotesCount(key, "y"))}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableRightBorder}>
                        <Typography variant="body2">
                          {getVotesPercentage(key, "y")}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableRightBorder}>
                        <Typography variant="body2">
                          {agmUtils.formatNumber(getVotesNoOfVoter(key, "y"))}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableRightBorder}>
                        <Typography variant="body2">
                          {agmUtils.formatNumber(getVotesCount(key, "n"))}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableRightBorder}>
                        <Typography variant="body2">
                          {getVotesPercentage(key, "n")}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableRightBorder}>
                        <Typography variant="body2">
                        {agmUtils.formatNumber(getVotesNoOfVoter(key, "n"))}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableRightBorder}>
                        <Typography variant="body2">
                          {agmUtils.formatNumber(getTotalVotesShares(key))}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableRightBorder}>
                        <Typography variant="body2">
                        {getVotesPercentage(key, "all")}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableRightBorder}>
                        <Typography variant="body2">
                          {agmUtils.formatNumber(getTotalVoter(key))} 
                        </Typography>
                      </TableCell>
                    </TableRow>
                    ))
                }
                </TableBody>
              </Table>
          </CardContent>
      </Card>
    </Grid>
    )    
  }

  const displayResolutions = () => {
    
    if(agmEvent === undefined) return ""
    if(agmEvent.length === 0) return ""
    return (
      
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
        <Card
        >
          <CardContent>
              <Typography variant="h4" className={classes.positiveNum}>
                {agmEvent.companyname}
              </Typography>
              <Typography variant="body1" className={classes.positiveNum}>
                {agmEvent.companyaddress}
              </Typography>
              <Typography variant="body1" className={classes.positiveNum}>
                {agmEvent.companyemail}
              </Typography>
              <Typography variant="body1" className={classes.positiveNum}>
                {agmEvent.companytelno}
              </Typography>
          </CardContent>

          <CardHeader
            className={classes.primaryHeading}
            //subheader= {agmEvent.companyaddress}
            title={agmEvent.eventname + " Resolutions"}
            titleTypographyProps={{variant:'h4' }}
            subheaderTypographyProps={{variant:'body1' }}
          />
            <CardContent>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableLeftBorder}>
                      <Typography variant="body1" className={classes.positiveNum}>
                        Resolutions
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {
                  Object.keys(agmEvent.resolutions).map((key, i) => (
                    <TableRow key={key}>
                      <TableCell className={classes.tableLeftBorder}>
                        <Typography variant="body1" className={classes.positiveNum}>
                          <div dangerouslySetInnerHTML={{__html:agmEvent.resolutions[key]}} />
                        </Typography>
                      </TableCell>
                    </TableRow>
                    ))
                }
                </TableBody>
              </Table>
          </CardContent>
      </Card>
    </Grid>
    )    
  }

  const getVoteShares = (key, votes, NAvotes) => {

    if(votes === undefined) return 0

    if(NAvotes[key] !== undefined){
      return "Abstain"
    }
    
    if(votes[key] === undefined) return 0
    return agmUtils.formatNumber(votes[key])
  } 


  const displayVotingDetails = () => {
    
    if(agmEvent === undefined) return ""
    if(agmEvent.length === 0) return ""
    return (
      
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
        <Card
        >
          <CardContent>
              <Typography variant="h4" className={classes.positiveNum}>
                {agmEvent.companyname}
              </Typography>
              <Typography variant="body2" className={classes.positiveNum}>
                {agmEvent.companyaddress}
              </Typography>
              <Typography variant="body2" className={classes.positiveNum}>
                {agmEvent.companyemail}
              </Typography>
              <Typography variant="body2" className={classes.positiveNum}>
                {agmEvent.companytelno}
              </Typography>
          </CardContent>

          <CardHeader
            className={classes.primaryHeading}
            //subheader= {agmEvent.companyaddress}
            title={agmEvent.eventname + " Details"}
            titleTypographyProps={{variant:'h4' }}
            subheaderTypographyProps={{variant:'body2' }}
          />
            <CardContent>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableLeftBorder}>
                      <Typography variant="body2" className={classes.positiveNum}>
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableLeftBorder}>
                      <Typography variant="body2" className={classes.positiveNum}>
                        NRIC
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableLeftBorder}>
                      <Typography variant="body2" className={classes.positiveNum}>
                        Email
                      </Typography>
                    </TableCell>
                   
                    {
                      Object.keys(resolutionsTitle).map((key, i) => (
                        <TableCell className={classes.tableLeftBorder} key={key}>
                          <Typography variant="body2" className={classes.positiveNum}>
                            {"(Voted For) " + resolutionsTitle[key].toUpperCase()}
                          </Typography>
                        </TableCell>
                        ))
                    }
                     {
                      Object.keys(resolutionsTitle).map((key, i) => (
                        <TableCell className={classes.tableLeftBorder} key={key}>
                          <Typography variant="body2" className={classes.positiveNum}>
                            {"(Voted Against) " + resolutionsTitle[key].toUpperCase()}
                          </Typography>
                        </TableCell>
                        ))
                    }
                    
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                {
                  Object.keys(voteDetails).map((key, i) => (
                    <TableRow key={key}>
                        <TableCell className={classes.tableLeftBorder}>
                          <Typography variant="body2" className={classes.positiveNum}>
                            {voteDetails[key].username}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableLeftBorder}>
                          <Typography variant="body2" className={classes.positiveNum}>
                            {voteDetails[key].nric}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableLeftBorder}>
                          <Typography variant="body2" className={classes.positiveNum}>
                            {voteDetails[key].email}
                          </Typography>
                        </TableCell>
                       
                        {
                          Object.keys(resolutionsTitle).map((keytitle, i) => (
                            <TableCell className={classes.tableRightBorder} key={keytitle+i}>
                              <Typography variant="body2" className={classes.positiveNum}>
                                {getVoteShares(resolutionsTitle[keytitle], voteDetails[key]?.yesvotes, voteDetails[key]?.notavailvotes)} 
                              </Typography>
                            </TableCell>
                            ))
                        }
                        {
                          Object.keys(resolutionsTitle).map((keytitle, i) => (
                            <TableCell className={classes.tableRightBorder} key={keytitle+key+i}>
                              <Typography variant="body2" className={classes.positiveNum}>
                                {getVoteShares(resolutionsTitle[keytitle], voteDetails[key]?.novotes, voteDetails[key]?.notavailvotes)} 
                              </Typography>
                            </TableCell>
                            ))
                        }
                      </TableRow>
                    ))
                }
                
                </TableBody>
              </Table>
          </CardContent>
      </Card>
    </Grid>
    )    
  }


  const GetData = () => {
    
    if(agmSelectedEvent.eventid === undefined) return
    const unsubscribe = firebase
    .firestore()
    .collection('agmvoting')
    .where('eventid', '==', agmSelectedEvent.eventid)
    .onSnapshot((snapshot) => {
      const newValues = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
      
      let votesols = calculateVotes(newValues)
      calculateVotesDetail(newValues)

      setVotings(votesols)
      
    })
    return () => { unsubscribe() }
  }

  
  const calculateVotesDetail = (votelist) => {

    let details = []
    let resolutions = []
    
    let dataheader

    if(votelist.length > 0){
      Object.keys(votelist[0].votes).forEach(function (reskey) {
        resolutions.push(reskey)
      })
  
      votelist.forEach(agmvote => {
        let datavoteyes = []
        let datavoteno = []
        let datavotenotavail = []
        dataheader = {
          "shares" : agmvote.shares,
          "eventid": agmvote.eventid,
          "eventdate": agmvote.eventdate,
          "userid" : agmvote.userid,
          "username": agmvote.username,
          "nric": agmvote.nric,
          "email": agmvote.email,
          "eventname": agmvote.eventname
        }
  
        Object.keys(agmvote.votes).forEach(function (reskey) {
  
          if(agmvote.votes[reskey] === 1){
            let yesvoteshares = agmvote.shares// * parseFloat(agmvote.votes[reskey])
            datavoteyes = {
              ...datavoteyes,
              [reskey] : yesvoteshares
            }
          }
          if(agmvote.votes[reskey] === 0){
            let novoteshares = agmvote.shares //* parseFloat(agmvote.votes[reskey])
            datavoteno = {
              ...datavoteno,
              [reskey] : novoteshares
            }
          }
          if(agmvote.votes[reskey] === 2){
            let notAllowvoteshares = agmvote.shares //* parseFloat(agmvote.votes[reskey])
            datavotenotavail = {
              ...datavotenotavail,
              [reskey] : notAllowvoteshares
            }
          }
  
        });
  
        dataheader.novotes = datavoteno
        dataheader.yesvotes = datavoteyes
        dataheader.notavailvotes = datavotenotavail
         
        // combine = {...dataheader, ...datavote }
        details.push(dataheader)
      });
    }
    
    setVoteDetails(details)
    setResolutionsTitle(resolutions)
  }

  const calculateVotes = (votelist) => {

    let votesols = []
    
    votelist.forEach(agmvote => {
      
      Object.keys(agmvote.votes).forEach(function (reskey) {

        let existRsol = votesols.find(x=>x.key === reskey)
        
        let yesvoteshares = 0
        let yesnumber  = 0
        let nonumber = 0
        let novoteshares = 0
        let numberofvoter =  0
        let totalVoteShares =  0

        if(agmvote.votes[reskey] === 1){
          yesvoteshares = agmvote.shares // * parseFloat(agmvote.votes[reskey])
          yesnumber = 1 //yesvoteshares > 0 ? 1 : 0
          nonumber = 0
          numberofvoter = 1
          totalVoteShares = agmvote.shares

        }else if(agmvote.votes[reskey] === 0){
          novoteshares = agmvote.shares
          nonumber = 1
          yesnumber = 0
          numberofvoter = 1
          totalVoteShares = agmvote.shares
        }
        
        if(existRsol !== undefined){
          existRsol.voteyescount += yesvoteshares
          existRsol.votenocount += novoteshares //(totalVoteShares - existRsol.voteyescount)
          existRsol.numberofvoter += numberofvoter
          existRsol.totalVoteShares += totalVoteShares
          existRsol.yesnumber += yesnumber
          existRsol.nonumber += nonumber
        }else{
          
          votesols.push({
            "key": reskey,
            "voteyescount": yesvoteshares,
            "votenocount": novoteshares, //totalVoteShares - yesvoteshares,
            "numberofvoter": numberofvoter,
            "totalVoteShares": totalVoteShares,
            "yesnumber": yesnumber,
            "nonumber": nonumber
          })
        }
      });
    });
    votesols.forEach(vote => {
      
      let yespercentage = vote.voteyescount / vote.totalVoteShares
      let nopercentage = 1 - yespercentage
      let totalPercentage = yespercentage + nopercentage
      vote.yespercentage = yespercentage
      vote.nopercentage = nopercentage
      vote.totalPercentage = totalPercentage
    });

    return votesols

  }
  const handleClickFilter = () => event => {
   
    let selected = companyEvents.find(x=>x.eventid === agmSelectedEvent.eventid)
    setAgmEvent(selected)
    GetData()
  }

  const getSupportedEvents = () => {
    
    const events = []
    companyEvents.forEach(ev => {
      events.push({
        name: ev.eventname,
        value: ev.eventid
      })
    });
    return events
  }
  
  const printPreview = () => event => {
    event.preventDefault()
    setOpenAdd(true)
  }

  const printAll = () => event => {
    event.preventDefault()
    window.print()
  }
 
  const handleCloseAdd = () => {
    setOpenAdd(false);
  }


  return (
    <div className={classes.root}>
       <main className={classes.content}>
       
       <Dialog 
          open={openAdd} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={true}
          maxWidth = {'lg'}
        >
           <DialogActions>
            <ButtonGroup>
              <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={printAll()}
                  >
                    Print
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={handleCloseAdd}
                  >
                    Close
                  </Button>
              </ButtonGroup>
          </DialogActions>
          <DialogContent>
          {displayVotingSummary()}
          {displayResolutions()}
          {displayVotingDetails()}
          </DialogContent>
         

      </Dialog>
       <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
        
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Autocomplete
                    id="eventid"
                    size="small"
                    name="eventid"
                    options={getSupportedEvents()}
                    getOptionLabel={option => option.name}
                    onChange={(event, newValue) => {
                      setAgmSelectedEvent({
                        eventid: newValue == null? "" : newValue.value
                      });
                    }}
                    renderInput={params => <TextField {...params} fullWidth label="Event Name" margin="dense" variant="outlined" />}
                  />
                </TableCell>
                <TableCell>
                <ButtonGroup>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={handleClickFilter()}
                  >
                    Show
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={printPreview()}
                  >
                    Preview
                  </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        {displayVotingSummary()}
        {displayResolutions()}
        {displayVotingDetails()}
        </Grid>
        
        </main>
    </div>
  );
};

export default ViewVotings;
