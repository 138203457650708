import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import firebase from "firebase/app";
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  CompanyList as CompanyListView,
  ViewVotings as ViewVotingsView,
  MyEventList as MyEventListView,
  VerifyUsers as VerifyUsersView,
  Account as AccountView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  ThankYou as ThankYouView,
  Home as HomeView,
  SetupEvents as SetupEventsView
  
} from './views';


const Routes = props => {
  
  firebase.auth().onAuthStateChanged(function(myuser) {
    if (!myuser) {
        //props.history.replace('/sign-in')
        return

    }else{
      if (!myuser.emailVerified) {
        //props.history.replace('/sign-in')
        return
      }
    }
  });

  return (
    <Switch>

      <RouteWithLayout exact path="/">
        <Redirect to="/home"/>
      </RouteWithLayout>

      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      
      <RouteWithLayout
        component={CompanyListView}
        exact
        layout={MainLayout}
        path="/companies"
      />
      <RouteWithLayout
        component={VerifyUsersView}
        exact
        layout={MainLayout}
        path="/verifyusers"
      />
      <RouteWithLayout
        component={SetupEventsView}
        exact
        layout={MainLayout}
        path="/eventview"
      />
      <RouteWithLayout
        component={ViewVotingsView}
        exact
        layout={MainLayout}
        path="/viewvotings"
      />
      <RouteWithLayout
        component={MyEventListView}
        exact
        layout={MainLayout}
        path="/eventlist"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
     
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={ThankYouView}
        exact
        layout={MinimalLayout}
        path="/thank-you"
      />
      
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MinimalLayout}
        path="/home"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />

      <Redirect to="/not-found"/>
      
    </Switch>
  );
};

export default Routes;
