// Replace this section with the config file of your own Firebase project. 
const firebaseConfig = {
  apiKey: "AIzaSyBa_WSoLZpiC8VSPKJzbtiLuJ2G3Khv3BY",
  authDomain: "agmweb-app.firebaseapp.com",
  databaseURL: "https://agmweb-app.firebaseio.com",
  projectId: "agmweb-app",
  storageBucket: "agmweb-app.appspot.com",
  messagingSenderId: "239957167443",
  appId: "1:239957167443:web:fe72cbe05640f59f1325a6",
  measurementId: "G-M1V7VBQVR0"
};

export default firebaseConfig;