import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';


import {
  Typography,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));



const TermService = props => {
  const { className, ...rest } = props;
  

  const classes = useStyles();

  const getTermCondition = () =>{

    return (
      `<div id="root" aria-hidden="true">
      <div class="jss1"><header class="MuiPaper-root MuiPaper-elevation4 MuiAppBar-root MuiAppBar-positionFixed jss3 MuiAppBar-colorPrimary mui-fixed">
      <div class="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">
      <div class="jss9">
      <div>&nbsp;</div>
      </div>
      </div>
      </header><main class="jss2">
      <h1 class="jss508">GENERAL TERMS AND CONDITIONS</h1>
      </main></div>
      </div>
      <div class="MuiDialog-root" role="presentation">
      <h2>&nbsp;</h2>
      <h2>SCOPE:</h2>
      <p>The use of this site is governed by these General Terms and Conditions. By logging into the site, you acknowledge having read these General Terms and Conditions and confirm their full acceptance. These General Terms and Conditions can be modified at any time. The current General Terms and Conditions cancels and replaces any previous versions, which are no longer usable against AGM Online. AGM Online can not be held responsible, in any way, for misuse of the service. By using our Services, you agree on behalf of yourself, your users and your attendees, (1) not to modify, prepare derivative works of, or reverse engineer, our Services; (2) knowingly or negligently use our Services in a way that abuses or disrupts our networks, user accounts, or the Services. These General Terms and Conditions may be modified at any time without notice by AGM Online. Users of the site are therefore invited to consult them regularly.</p>
      <h2>&nbsp;</h2>
      <h2>SERVICES:</h2>
      <p>&nbsp;</p>
      <p><strong>DESCRIPTION OF PROVIDED SERVICES</strong></p>
      <p>AGM Online offers virtual online annual general meeting and voting management for any company. Shareholder register with the platform and participate in voting with the company general meeting online.</p>
      <p>&nbsp;</p>
      <p><strong>GENERAL PRECAUTIONS OF USE</strong></p>
      <p>AGM Online strongly recommends using all the precautions available to prevent fraudulent use. Non-exhaustive list:</p>
      <ul>
      <li>Use a unique and complex password.</li>
      <li>Change your password regularly.</li>
      <li>Any other special precautions to avoid an intrusion on the PCs or smartphones used (via virus, trojan, theft etc).</li>
      </ul>
      <h2>&nbsp;</h2>
      <h2>LIMITATION OF LIABILITY:</h2>
      <p>&nbsp;</p>
      <p><strong>PRIVATE NOTICE TO SHAREHOLDERS UNCER THE PERSONAL DATA PROTECTION ACT 2010 (PDPA)</strong></p>
      <p>AGM Online (&ldquo;AO&rdquo; or &ldquo;we&rdquo; or &ldquo;us&rdquo; or &ldquo;our&rdquo;) strives to protect your personal data in accordance with the Personal Data Protection Act 2010 (&ldquo;the Act&rdquo;). The Act was enacted to regulate the processing of personal data to comply with the Act, we are required to manage the personal data that we collect from you relating to your shareholding in companies that you wish to register to attend their virtual meeting.</p>
      <p>The purposes for which your personal data may be used are, but not limited to:</p>
      <ul>
      <li>Internal record keeping including but not limited to the registration and management of your shareholding in the companies that currently or previously that have engaged our services</li>
      <li>To provide services to you</li>
      <li>To communicate with you as a shareholder/ proxy for a shareholder of those companies that you hold equity shares</li>
      <li>To better understand your needs as shareholder</li>
      <li>For security and fraud prevention purposes</li>
      <li>For the purposes of statistical analysis of data</li>
      <li>For marketing activities</li>
      <li>For the purposes of our corporate governance</li>
      <li>To send you event invitations based on selected events</li>
      <li>To comply with any legal, statutory and/or regulatory requirements</li>
      <li>For the purposes of inclusion in media engagements and/or any relevant or related events</li>
      <li>For the purposes of us preparing guest invitations, registration and/or sign-ups for our events</li>
      <li>For the purposes of printed and on-line publications</li>
      </ul>
      <p>(collectively, &ldquo;the Purposes&rdquo;)</p>
      <p>Your personal data is or will be collected from information provided by you, including but not limited to, postal, facsimile, telephone, copy of your national identity card and e-mail communications with or from you, and information provided by third parties, including but not limited to, Bursa Malaysia Berhad and any other stock exchange, and your stockbrokers and remisiers.</p>
      <p>You may be required to supply us with your name, correspondence address, telephone number, facsimile number, mail address and copy of your national identity card for verification. If you fail to supply us with such personal data, we may not be able to process and/or disclose your personal data for any of the Purposes.</p>
      <p>Please be informed that your personal data may be disclosed, disseminated and/or transferred to companies within the AO and the group of companies that you registered or wish to register with in order to attend the virtual meeting as organized by those companies (including the holding company, subsidiaries, related and affiliated companies, both local and international), whether present or future (collectively, &ldquo;the Group&rdquo;) or to any third party organizations or persons for the purpose of fulfilling our obligations to you in respect of the Purposes and all such other purposes that are related to the Purposes and also in providing integrated services, maintaining and storing records including but not limited to the share registrar(s) appointed by us to manage the registration of shareholders.</p>
      <p>The processing, disclosure, dissemination and/or transfer of your personal data by us and/or the Group and/or third party organizations or persons may result in your personal data being transferred outside of Malaysia.</p>
      <p>To this end, we are committed to ensuring the confidentiality, protection, security and accuracy of your personal data made available to us. It is your obligation to ensure that all personal data submitted to us and retained by us are accurate, not misleading, updated and complete in all aspects. For the avoidance of doubt, we and/or the Group and/or our or their employees or authorized officers or agents will not be responsible for any personal data submitted by you to us that is inaccurate, misleading, not up to date and incomplete.</p>
      <p>Further, we may request your assistance to procure the consent of third parties whose personal data is made available by you to us and you hereby agree to use your best endeavors to do so.</p>
      <p>In addition, you may access to your personal data with your registered user name and password by signing in to our designated webpage:-</p>
      <ul>
      <li>you require access to and/or wish to make corrections to your personal data subject to compliance of such request for access or correction not being refused under the provisions of the Act and/or existing laws; or</li>
      <li>you wish to enquire about your personal data.</li>
      </ul>
      <p>Any personal data retained by us shall be destroyed and/or deleted from our records and system in accordance with our retention policy in the event such data is no longer required for the said Purposes.</p>
      <p>We trust that you will consent to the processing of your personal data and that you declare that you have read, understood and accepted the statements and terms herein.</p>
      <p>&nbsp;</p>
      <p><strong>OTHERS</strong></p>
      <p>Any downloaded content is at the user's own risk and under his sole responsibility. Consequently, AGM Online can not be held responsible for any damage to the user's computer or any loss of data resulting from the download. The hyperlinks available on the website, redirecting to other resources on the Internet, do not engage the responsibility of AGM Online as to their content and use. AGM Online will in no way be held responsible for any damage of any kind resulting from the interpretation or use of the information and / or documents available on this site. <br /><br />AGM Online can not be held responsible for material damage related to the use of the site. In addition, the user of the site agrees to access the site using recent equipment, not containing viruses and with a browser last generation updated. AGM Online can not be held liable for indirect damages (such as for example a loss of market or loss of opportunity) resulting from the use of the site.<br /><br />Interactive spaces (possibility to ask questions in the contact area) are available to users. AGM Online reserves the right to remove, without prior notice and without justification, any content posted in this space. Where applicable, AGM Online also reserves the right to prosecute users, particularly in the event of a racist, abusive, defamatory or pornographic message, regardless of the medium used (text , photography&hellip;). Loans, interest payments, as well as the general management of linking lenders and borrowers are managed and controlled by the exchange. AGM Online can not be held responsible for any disputes related to these activities. Users are invited to contact the exchanges directly, AGM Online can not play the role of intermediary.</p>
      <p>&nbsp;</p>
      <p><strong>INDEMNITY CLAUSE AND CLASS ACTION WAIVER:</strong><br /><br />Subject to the above mentioned exception, AGM Online will under no circumstances be liable for any damages for loss of use, business interruption, lost profits, revenue or opportunity, or for any indirect, special, exemplary, incidental, punitive or consequential loss or damages of any kind or nature. AGM Online and User agree that each other may bring claims against the other only on its or his individual capacity and not as a plaintiff or class member in any class or representative action.</p>
      <p>&nbsp;</p>
      <p><strong>ACCESS TO THE SITE:</strong></p>
      <p>AGM Online endeavors to provide access to the site 24 hours a day, 7 days a week, except in case of &ldquo;force majeure&rdquo; or an event beyond the control of AGM Online, and subject to possible breakdowns. An interruption due to technical maintenance may however be decided by AGM Online, who will then do its best to communicate to users beforehand the dates and times of the intervention.<br />Therefore, AGM Online can not guarantee availability of the site and / or services, reliability of transmissions and performance in terms of response time or quality.</p>
      <p><br />The responsibility of AGM Online can not be held in case of impossibility of access to this site and / or use of the services. In addition, AGM Online may be required to interrupt the site or part of the services, at any time without notice, all without entitlement to compensation. The user acknowledges and agrees that AGM Online is not responsible for interruptions, and any direct or indirect consequences that may arise for the user or any third party.</p>
      <p>&nbsp;</p>
      <p><strong>INTELLECTUAL PROPERTY:</strong></p>
      <p>AGM Online is the exclusive owner of all intellectual property rights relating to both the structure and content of the Site [and its subsites]. All elements appearing on this site (names of strategies, images, databases, brands, illustrations, logos, drawings, models, layout, downloadable documents, etc.) are protected as works of the spirit by international law on copyright and intellectual property. Except with prior written authorization, any reproduction, representation, adaptation, partial or complete modification of any element composing the site, by any means whatsoever, is prohibited under penalty of prosecution.</p>
      <p>&nbsp;</p>
      <p><strong>RESPONSIBILITY:</strong></p>
      <p>AGM Online declines all responsibility in the event of voluntary or involuntary transmission by the User to a third party of the access codes which it is his responsibility to keep in a safe place and whose use by a third party could result in the disclosure, alteration or the deletion of the personal data of the user. AGM Online undertakes to guarantee the confidentiality of personal data and to ensure that persons authorized to process personal data undertake to respect confidentiality or are subject to an appropriate legal obligation of confidentiality.</p>
      <p>&nbsp;</p>
      <p><strong>DATA SECURITY:</strong></p>
      <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
      <p>&nbsp;</p>
      <p><strong>COOKIES:</strong></p>
      <p>For statistical and display purposes, this site uses cookies. These are small text files stored on your hard disk to record technical data about your navigation. Some parts of this site can not be functional without the acceptance of cookies. You can manage the settings for saving cookies from your browsers.</p>
      </div>`
    )
  }
  return (

    <div className={classes.root}>
      
      <div dangerouslySetInnerHTML={{__html:getTermCondition()}} />
      
     
    </div>

    
  );
};

TermService.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default TermService;
