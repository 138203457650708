import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import HttpsIcon from '@material-ui/icons/Https';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { useTranslation } from 'react-i18next'

import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardHeader,
  Avatar,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0,4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0,0),
    }
  },
  actions: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'center'
  }, 
  heading: {
    fontSize: theme.typography.pxToRem(20),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  quoteContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/automation2.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  grid: {
     padding: theme.spacing(4, 2, 5, 2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
}));



const LearnMore = props => {
  //const { className } = props;
  
  const classes = useStyles();
  const { t } = useTranslation()
  return (

    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.grid}
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Card
            className={classes.grid}
          >
            <CardHeader 
              title="Less Expensive"
              titleTypographyProps={{variant:'h3' }}
              subheaderTypographyProps={{variant:'body1' }}
              avatar={
                <Avatar
                    className={classes.avatar}
                  >
                    <AutorenewIcon />
                  </Avatar>  
              }
            />
            <CardContent>
            <Typography variant="h6" color="textSecondary" component="p">
            Virtual AGM are always looking to save money, with shrinking budgets. 
            Thanks to virtual events, the overall cost of putting on your event will be greatly reduced
          </Typography>
            </CardContent>
          </Card>

          
    
          </Grid>
      <Grid
        className={classes.grid}
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >   
          <Card
            className={classes.grid}
          >
            <CardHeader 
              title="Time Saving"
              titleTypographyProps={{variant:'h3' }}
              subheaderTypographyProps={{variant:'body1' }}
              avatar={
                <Avatar
                    className={classes.avatar}
                  >
                    <SettingsApplicationsIcon />
                  </Avatar>  
              }
            />
            <CardContent>
            <Typography variant="h6" color="textSecondary" component="p">
            Because your event is virtual, you’ll also save yourself and attendees so much time. 
            Although virtual events do require some setup time it’s tremendously less than that of an in-person event
          </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          className={classes.grid}
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Card
            className={classes.grid}
          >
            <CardHeader 
              title="Easy to Measure Results"
              titleTypographyProps={{variant:'h3' }}
              subheaderTypographyProps={{variant:'body1' }}
              avatar={
                <Avatar
                    className={classes.avatar}
                  >
                    <LocalAtmIcon />
                  </Avatar>  
              }
            />
            <CardContent>
            <Typography variant="h6" color="textSecondary" component="p">
            It is  easier to gather voting data on your AGM event 
            and attendees during your AGM virtual event than an in-person event. 
            Why? Because everything is done online, so it can be easily tracked. 
          </Typography>
            </CardContent>
          </Card>
      </Grid>
      <Grid
        className={classes.grid}
        item
        lg={3}
        sm={6}
        xl={3}
        xs={12}
      >
          <Card
            className={classes.grid}
          >
            <CardHeader 
              title="It’s Global"
              titleTypographyProps={{variant:'h3' }}
              subheaderTypographyProps={{variant:'body1' }}
              avatar={
                <Avatar
                    className={classes.avatar}
                  >
                    <HttpsIcon />
                  </Avatar>  
              }
            />
            <CardContent>
            <Typography variant="h6" color="textSecondary" component="p">
              One of the biggest roadblocks to event attendance has been eliminated: Travel costs. 
              With a virtual AGM event, the attendee only needs a computer and an Internet connection. Anyone can attend easily than ever
          </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>

    
  );
};

LearnMore.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default LearnMore;
