import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import Circular from '../Circular/Circular'
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import PopupMessage from '../PopupMessage/PopupMessage'
import { useTranslation } from 'react-i18next'
import agmUtils  from "agmUtils"
import 'firebase/functions'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../../theme';


import {
  Card,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  TableRow,
  TableCell,
  LinearProgress,
  Table,
  Radio,
  CardHeader,
  IconButton,
  FormControlLabel,
  CardContent,
  Typography,
  RadioGroup,
  FormControl,
  CardActions,
  TableBody
} from '@material-ui/core';

const useStyles =  makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  avatar: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  text: {
    width: 120,
    margin: 5
  },
  tableRow: {
    padding: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  tableCell: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.white
  },
  CardTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    fontWeight: 700,
    //textAlign: 'center'
  },
  negativeNum: {
    color: theme.palette.error.main
  },
  secondaryHeading: {
    color: theme.palette.success.main,
  },
  
}));

const RegisterVotes = forwardRef((props, ref) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const refCircular = useRef(null);
  const { user } = useAuth()
  const { uid: userId } = user || {}
  const refmsg = useRef(null);
  
  const [loading, setLoading] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  
  const [showResult, setShowResult] = React.useState(false);
  const [companyEvents, setCompanyEvents] = React.useState([]);
  const [radiovalue, setRadiovalue] = React.useState([]);
  const [votingResults, setVotingResults] = useState([])
  const [attendance, setAttendance] = useState([])
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  
  useEffect(() => {
      
    if(attendance.eventid){

      const unsubscribe = firebase
          .firestore()
          .collection('agmevents')
          .doc(attendance.eventid).onSnapshot(doc => {
            
            setCompanyEvents(doc.data())
        })
      return () => { unsubscribe() }
    }
    
  }, [attendance.eventid])

  const GetData = (_att) => {
    
    const unsubscribe = firebase
        .firestore()
        .collection('agmvoting')
        .doc(_att.id).onSnapshot(doc => {
          setVotingResults(doc.data());
      })
    return () => { unsubscribe() }
  }
  
  const handleOpen = (_att, _showRes) => {

    console.log(_att)
    setAttendance(_att)
    setRadiovalue([])
    setOpenAdd(true);
    GetData(_att)
    setShowResult(_showRes)
  }

  const handleCloseAdd = () => {
    setLoading(false)
    setOpenAdd(false);
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen
    };
  });

  const handleClickCastVotes = (comp) => event => {
      event.preventDefault();
      
      console.log(comp)
      let toproceed = true
      let votes = {}
      Object.keys(comp.resolutions).forEach(function (key) {

        console.log(radiovalue["voting"+ key])
        if(radiovalue["voting"+ key] === undefined){
          alert("Please make you vote for "+ key + ". If it is not applicable to you, please choose 'Abstain'")
          toproceed = false
          return
        }
        votes[key] = parseInt(radiovalue["voting"+ key])
      })

      if(!toproceed) return 

      if(!window.confirm("Do you really want to save the vote? Once it is voted you are not allowed to modify or delete it")){
        return 
      }
    
      setLoading(true)
      const data = {
        "eventid": comp.eventid,
        "userid": userId,
        "shares": parseFloat(attendance.shares),
        "voted_at": new Date(),
        "eventdate": comp.eventdate,
        "eventname" : comp.eventname,
        "votes": votes,
        "attid" : attendance.id,
        "email": attendance.email,
        "username" : attendance.username,
        "nric" : attendance.nric
      };

      console.log(data)
      const app = firebase.app();
      const functions1 = app.functions("asia-east2");
      var addVoting = functions1.httpsCallable('addVoting');
      addVoting(data)
      .then((result) => {
        refmsg.current.handleOpen(result.data.message, "info");
        setLoading(false)
        handleCloseAdd()  
      })
      .catch((error) => {
        refmsg.current.handleOpen(error.message, "error");
        setLoading(false)
        handleCloseAdd()  
      });
  };

  
  const getProgress= () =>{
    
    if(!loading) return ""

    return (
        <DialogContentText >
              <LinearProgress 
                color="secondary" 
                size={50}
                thickness={4}
              />
            </DialogContentText>
    )
      
  }

  const handleChangeRadio = (event) => {

    setRadiovalue({
      ...radiovalue,
      [event.target.name]: event.target.value
    })
  };

  const getRadioValue = (id) => {

    Object.keys(radiovalue).forEach(function (key) {
      if(key === id) return radiovalue[key]
    })

  }
  const getCastButton = () =>{

    if(showResult) return ""

    return (
      <CardActions>
                
                <Button
                  fullWidth
                  size="medium"
                  color="primary"
                  variant="contained"
                  disabled={attendance.voted}
                  onClick={handleClickCastVotes(companyEvents)}
                >
                  Cast Votes
                </Button>
            </CardActions>
    )
  }

  const getRadioDisplay = (key) => {

    if(showResult){

      let msg = ""
      if(votingResults?.votes[key] === 1) {
        msg = agmUtils.formatNumber(votingResults?.shares) + " voted for Yes"
      }else if(votingResults?.votes[key] === 0) {
        msg = agmUtils.formatNumber(votingResults?.shares) + " voted against"
      }else{
        msg = agmUtils.formatNumber(votingResults?.shares) + " Abstain"
      }

      return (
         <div>
          {msg}
        </div>
      )
    }else{
      return (
        <FormControl component="fieldset">
          <RadioGroup aria-label={"voting"+ key} name={"voting"+ key} value={getRadioValue("voting"+ key)} onChange={handleChangeRadio}>
            <FormControlLabel disabled={attendance.voted} value="1" control={<Radio />} label="Yes" />
            <FormControlLabel disabled={attendance.voted} value="0" control={<Radio />} label="No" />
            <FormControlLabel disabled={attendance.voted} value="2" control={<Radio />} label="Abstain" />
          </RadioGroup>
        </FormControl>
      )
    }
    
  }

    const displayResolutions = () => {
    
      if(companyEvents === undefined) return ""
      if(companyEvents.length === 0) return ""
      
      return (
          <Card
          >
            <CardHeader
              className={classes.primaryHeading}
              //subheader= {comp.registration}
              title={companyEvents.eventname}
              titleTypographyProps={{variant:'h5' }}
              subheaderTypographyProps={{variant:'body1' }}
            />
           
              <CardContent>
                <Table size="small">
                <TableBody>
                  {
                    Object.keys(companyEvents.resolutions).map((key, i) => (
                      <TableRow>
                        <TableCell>
                          <Typography variant="body2" className={classes.positiveNum}>

                          <div dangerouslySetInnerHTML={{__html:companyEvents.resolutions[key]}} />

                            {/* {companyEvents.resolutions[key]} */}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" className={classes.positiveNum}>
                            {
                              getRadioDisplay(key)
                            }
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" className={classes.positiveNum}>
                            
                          </Typography>
                        </TableCell>
                      </TableRow>
                      ))
                  }
                </TableBody>
              </Table>
            </CardContent>
            <CardContent>{getProgress()}</CardContent>
            {getCastButton()}
        </Card>
      )    
    }

  

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Circular ref={refCircular} />
      <PopupMessage ref={refmsg} />
      <Dialog 
          open={openAdd} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={matches}
          maxWidth = {'lg'}
          >
            <CardHeader
              className={classes.CardTitle}
              action={
                <IconButton 
                  className={classes.title}
                  onClick={handleCloseAdd}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              title={"Voting Resolutions "}
              //titleTypographyProps={{variant:'h4' }}
            />
          <DialogContent>
              {/* {getProgress()} */}
              {displayResolutions()}
                
          </DialogContent>
        </Dialog>
    </Card>
  );
});

RegisterVotes.propTypes = {
  className: PropTypes.string
};

export default RegisterVotes;
