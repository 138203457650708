import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Circular from '../Circular/Circular'
import firebase from "firebase/app";
import moment from 'moment';
import HomeIcon from '@material-ui/icons/Home';
import { useAuth } from "use-auth.js";
import PopupMessage from '../PopupMessage/PopupMessage'
import { useTranslation } from 'react-i18next'
import Alert from '@material-ui/lab/Alert';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../../theme';

import {
  Card,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  TableRow,
  TableCell,
  LinearProgress,
  Table,
  CardHeader,
  IconButton,
  TextField,
  InputAdornment,
  CardContent,
  Typography,
  CardActions,
  TableBody,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';

const useStyles =  makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1,1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0,0)
    },
  },
  content: {
    padding: 0
  },
  avatar: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  text: {
    width: 120,
    margin: 5
  },
  tableRow: {
    padding: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  tableCell: {
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: theme.palette.white
  },
  CardTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    fontWeight: 700,
    //textAlign: 'center'
  },
  negativeNum: {
    color: theme.palette.error.main
  },
  secondaryHeading: {
    color: theme.palette.success.main,
  },
  
}));

const RegisterAGM = forwardRef((props, ref) => {
  const { companies } = props;
  const classes = useStyles();
  const refCircular = useRef(null);
  const { user } = useAuth()
  const { uid: userId } = user || {}
  const refmsg = useRef(null);
  const { t } = useTranslation()
  
 
  const [loading, setLoading] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [msgStatus, setMsgStatus] = React.useState("");
  const [companyEvents, setCompanyEvents] = React.useState([]);
  const [companyid, setCompanyid] = React.useState("");
  const [values, setValues] = useState([])
  const [userattendance, setUserattendance] = React.useState([]);
  const [userData, setUserData] = useState([])
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
        
    if(userId){

      const unsubscribe = firebase
          .firestore()
          .collection('agmusers')
          .doc(userId).onSnapshot(doc => {
          setUserData(doc.data());
        })
      return () => { unsubscribe() }
    }
    
  }, [userId])

  useEffect(() => {
    
    //if(companyid === undefined) return

    let mydate = new Date();
    mydate.setHours(0, 0, 0, 0);
  
    const unsubscribe = firebase
    .firestore()
    .collection('agmevents')
    .where('plcid', '==', companyid)
    .where('eventdate', '>=', mydate)
    .onSnapshot((snapshot) => {
      const newValues = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
      
      setCompanyEvents(newValues);
    })
    return () => { unsubscribe() }

}, [companyid])

  useEffect(() => {
    
  if(userId === undefined) return

  let mydate = new Date();
  mydate.setHours(0, 0, 0, 0);

  const unsubscribe = firebase
  .firestore()
  .collection('agmuserattendance')
  .where('userid', '==', userId)
  .onSnapshot((snapshot) => {
    const newValues = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }))
    
    setUserattendance(newValues);
  })
  return () => { unsubscribe() }

}, [userId])


  const handleClickOpen = (msg, status) => {
    refmsg.current.handleOpen(msg, status);
  };


  const handleOpen = (_compid) => {
    
    setCompanyid(_compid)
    setOpenAdd(true);
  }

  const handleCloseAdd = () => {
    setLoading(false)
    setOpenAdd(false);
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen
    };
  });

  const handleChange = event => {
    event.preventDefault();
    
    setValues({
      ...values,
      [event.target.name]: event.target.type === 'checkbox'? event.target.checked : event.target.value
    });

  }
  

  const handleClickRegister = (agmevent) => event => {
      event.preventDefault();
      
      setMsgStatus("")
      
      if(!userData.nric_uploaded || userData.investorid === "" || userData.name === ""){
        alert("Please go to Account menu to fill in your name, NRIC and upload the NRIC first before registering event")
        return
      }
      
      let assignproxy = parseFloat(values["assignproxy"+agmevent.id]) === 1
      let toAttend = parseFloat(values["attend"+agmevent.id]) === 1
      let proxyEmail = values["emailproxy"+agmevent.id] === undefined? "" : values["emailproxy"+agmevent.id]

      if(toAttend && assignproxy){
        alert("If you plan to attend the AGM, please uncheck assign proxy and delete the proxy's email")
        return
      }
      if(!toAttend && !assignproxy){
        alert("If you do not plan to attend the AGM, please assign proxy and fill in the proxy's email")
        return
      }
      if(!toAttend && assignproxy && proxyEmail === ""){
        alert("Please fill in the proxy's email")
        return
      }

      const data = {
        "eventid": agmevent.id,
        "shares": parseFloat(values["shares"+agmevent.id]),
        "status": "unconfirmed",
        "eventdate": agmevent.eventdate,
        "userid": userId,
        "attend": toAttend,
        "assignproxy": assignproxy,
        "eventname": agmevent.eventname,
        "username" : userData.name,
        "nric" : userData.nric,
        "email" : userData.email,
        "filetype" : userData.filetype,
        "proxyemail" : proxyEmail,
        "created_at" : new Date(),
        "proxyuser" : false,
        "votingTimeOut": agmevent.eventdate
      }
      
      let docid = agmevent.id + "_" + userId
      firebase.firestore().collection("agmuserattendance").doc(docid).set(data,{merge:true})
      .then(function () {
        handleCloseAdd()
        handleClickOpen(t('common.msg.successupdate'), "info")
        // setMsgStatus(t('common.msg.successupdate'))
      })
      .catch(function(error) {
        handleCloseAdd()
        handleClickOpen(error, "error")
    });

  };
  

  const alredyRegister = (eventid) =>{
    
    let matchdocid = eventid + "_" + userId
    let result = userattendance.find(x=>x.eventid === eventid && x.id === matchdocid)
    return result === undefined ? false: true 
  }


  const getNumberOfShares = (eventid) =>{
    
    let matchdocid = eventid + "_" + userId
    if(!alredyRegister(eventid)) return values["shares"+eventid]
    let result = userattendance.find(x=>x.eventid === eventid && x.id === matchdocid)
    return result.shares 
  }

  const getProxyEmail = (eventid) =>{
    
    let matchdocid = eventid + "_" + userId
    if(!alredyRegister(eventid)) return values["proxyemail"+eventid]
    let result = userattendance.find(x=>x.eventid === eventid && x.id === matchdocid)
    return result.proxyemail 
  }

  const getAssignProxy = (eventid) =>{
    
    let matchdocid = eventid + "_" + userId
    if(!alredyRegister(eventid)) return parseFloat(values["assignproxy"+eventid])
    let result = userattendance.find(x=>x.eventid === eventid && x.id === matchdocid)
    return result.assignproxy ? 1: 0
  }

  const getAttend = (eventid) =>{
    
    let matchdocid = eventid + "_" + userId
    if(!alredyRegister(eventid)) return parseFloat(values["attend"+eventid])
    let result = userattendance.find(x=>x.eventid === eventid && x.id === matchdocid)
    return result.attend ? 1: 0
  }

  const getCompanyName = (id) => {

    if(companies === undefined) return ""

    let comp = companies.find(x=>x.id ===id)
    return comp?.name

  }
  const getProgress= () =>{
    
    if(!loading) return ""

    return (
        <DialogContentText >
              <LinearProgress 
                color="secondary" 
                size={50}
                thickness={4}
              />
            </DialogContentText>
    )
      
  }

  const getProxy= (id) =>{
    
    return (
      <TableRow>
        <TableCell>
        I want to assign proxy agent 
        </TableCell>
        <TableCell >
        <Typography variant="body1" className={classes.positiveNum}>
        <FormControl component="fieldset">
            <RadioGroup 
              aria-label={"assignproxy"+ id} name={"assignproxy"+ id} value={getAssignProxy(id)} onChange={handleChange}>
              <FormControlLabel value={1} control={<Radio />} label="Yes" disabled={alredyRegister(id)} />
              <FormControlLabel value={0} control={<Radio />} label="No" disabled={alredyRegister(id)} />
            </RadioGroup>
          </FormControl>
          <TextField
            fullWidth
            margin="dense"
            name={"emailproxy"+ id}
            onChange={handleChange}
            helperText="Input Proxy's email"
            required
            value={getProxyEmail(id)}
            disabled={alredyRegister(id)} 
            variant="outlined"
          />
        </Typography>
        </TableCell>
      </TableRow>
    )
      
  }

  const getRegisterMsg = (event) => {

    if(!alredyRegister(event.eventid)) return ""

    return (
                <TableRow>
                  <TableCell colSpan={2}>
                  <Alert severity="warning">You already registered for this event. If you want to unregister please go to "My AGM Events" menu to delete it.
                      Please note if the attendance is confirmed you will not be able to delete it. 
                      Please just ignore it if you plan not to attend or vote for this event.</Alert> 
                  </TableCell>
                </TableRow>
      )

  }
  const hasError = field =>
    values?.shares === "" ? true : false;

  const displayEvents = () => {
    
    if(companyEvents.length === 0) 
    {
      return (
        <Card>          
          <CardContent>
             <Typography variant="body1" className={classes.positiveNum}>
             <Alert severity="warning">No Event Found in {getCompanyName(companyid)}</Alert> 

              </Typography>
          </CardContent>
      </Card>
      )
    }

    return (
      companyEvents.map(event => (
        <Card
        >
          <CardHeader
            className={classes.primaryHeading}
            //subheader= {comp.registration}
            title={event.eventname}
            titleTypographyProps={{variant:'h5' }}
            subheaderTypographyProps={{variant:'body1' }}
          />
            <CardContent>
              <Table size="small">
                <TableBody>
                  {getRegisterMsg(event)}
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Event Date:</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {moment(new Date(event.eventdate.seconds * 1000)).format('ll')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Number of Shares Held: </span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                      <Typography variant="body1" className={classes.positiveNum}>
                        <TextField
                          fullWidth
                          margin="dense"
                          name={"shares"+ event.id}
                          onChange={handleChange}
                          required
                          value={getNumberOfShares(event?.id)}
                          disabled={alredyRegister(event?.id)} 
                          variant="outlined"
                          error={hasError('shares')}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <HomeIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                  I want to attend AGM 
                  </TableCell>
                  <TableCell >
                  <Typography variant="body1" className={classes.positiveNum}>
                    <FormControl component="fieldset">
                      <RadioGroup 
                        aria-label={"attend"+ event.id} name={"attend"+ event.id} value={getAttend(event.id)} onChange={handleChange}>
                        <FormControlLabel value={1} control={<Radio />} label="Yes" disabled={alredyRegister(event?.id)} />
                        <FormControlLabel value={0} control={<Radio />} label="No" disabled={alredyRegister(event?.id)} />
                      </RadioGroup>
                    </FormControl>
                   </Typography>
                  </TableCell>
                </TableRow>
                {getProxy(event.id)}
                </TableBody>
              </Table>
          </CardContent>
          <CardActions>
            <Button
              disabled={alredyRegister(event?.id)} 
              onClick={handleClickRegister(event)} 
              color="secondary"
              variant="contained"
              fullWidth
              startIcon={<AddCircleIcon />}>
              {'Register'}
            </Button>
          </CardActions>
          <CardContent>
             <Typography variant="body1" className={classes.positiveNum}>
              {msgStatus}
              </Typography>
          </CardContent>
      </Card>

        )) 
    )    
  }


  return (
    <Card
    >
      <Circular ref={refCircular} />
      <PopupMessage ref={refmsg} />
      <Dialog 
          open={openAdd} 
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen={matches}
          maxWidth = {'md'}
          >
            <CardHeader
              className={classes.CardTitle}
              action={
                <IconButton 
                  className={classes.title}
                  onClick={handleCloseAdd}
                  aria-label="settings">
                  <CancelIcon />
                </IconButton>
              }
              title={"Register AGM Event " + getCompanyName(companyid)}
              //titleTypographyProps={{variant:'h4' }}
            />
          <DialogContent>
              {getProgress()}
              {displayEvents()}
                
          </DialogContent>
        </Dialog>
    </Card>
  );
});

RegisterAGM.propTypes = {
  className: PropTypes.string
};

export default RegisterAGM;
