import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import palette from "theme/palette";
import RegisterAGM from '../RegisterAGM/RegisterAGM'
import HomeIcon from '@material-ui/icons/Home';
import firebase from "firebase/app";
import {
  Card,
  CardContent,
  Button,
  Typography,
  CardHeader,
  Table,
  TableRow,
  TableCell,
  TextField,
  CardActionArea,
  Grid,
  CardActions,
  InputAdornment,
  TableBody,
  TableHead,
  ButtonGroup
} from '@material-ui/core';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1,1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1,1)
    },
  },
  card: {
    padding: theme.spacing(2,2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1,1)
    },
    height: 400
  },
  grid: {
    alignItems:"stretch"
 },
 texttitle: {
   color: palette.text.white
 },
 drawer: {
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
},
primaryHeading: {
   fontSize: theme.typography.pxToRem(15),
   color: theme.palette.primary.main,
 },
 secondaryHeading: {
   color: theme.palette.success.main,
 },
 positiveNum: {
   color: theme.palette.primary.main
 },
 negativeNum: {
   color: theme.palette.warning.main
 },
drawerPaper: {
  width: drawerWidth,
},
menuButton: {
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
},
toolbar: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  padding: theme.spacing(3,3),
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(1,1)
  },
  minHeight: 600
},
}));

const CompanyList  = props => {
  const classes = useStyles();
  
  const [companies, setCompanies] = useState([])
  const [filtercompanies, setFilterCompanies] = useState([])
  const refregister = useRef(null);
  const [filtervalue, setFiltervalue] = useState([])
  
  firebase.auth().onAuthStateChanged(function(myuser) {
    //userId = myuser.uid
    if (!myuser) {
        props.history.replace('/sign-in')
        return

    }else{
      if (!myuser.emailVerified) {
        props.history.replace('/sign-in')
        return
      }
    }
  });
  
  useEffect(() => {
    
      const unsubscribe = firebase
      .firestore()
      .collection('agmplcs')
      .onSnapshot((snapshot) => {
        const newValues = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        
        setCompanies(newValues);
        setFilterCompanies(newValues);
      })
      return () => { unsubscribe() }

  }, [])

  const displayCompanies = () => {
    
    if(filtercompanies === undefined) return ""

    return (
      filtercompanies.map(comp => (
        
        <Grid
          item
          lg={4}
          md={6}
          xl={4}
          xs={12}
          key={comp.id}
        > 
         <Card
            className={classes.card}
          >
          <CardHeader
            className={classes.primaryHeading}
            subheader= {comp.registration}
            title={comp.name}
            titleTypographyProps={{variant:'h5' }}
            subheaderTypographyProps={{variant:'body1' }}
          />
          <CardActionArea>
            <CardContent>
              <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" className={classes.positiveNum}>
                        <span>Address:</span>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" className={classes.positiveNum}>
                        {comp.address}
                      </Typography>
                    </TableCell>
                    
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" className={classes.positiveNum}>
                        Tel No:
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" className={classes.positiveNum}>
                        {comp.telno}
                      </Typography>
                    </TableCell>
                    </TableRow>
                  <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      Email:
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {comp.email}
                      </Typography>
                  </TableCell>
                  </TableRow>
                  <TableRow>
                  
                    <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                        Stock Code:
                    </Typography>
                    </TableCell>
                  
                    <TableCell variant="body1" className={classes.positiveNum}>
                    { comp.stockcode }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
          </CardContent>
          </CardActionArea>
          <CardActions>
              <Button
                fullWidth
                size="medium"
                color="primary"
                variant="contained"
                onClick={handleClickRegister(comp.id)}
                //startIcon={<HttpsIcon />}
              >
                View Events
              </Button>
          </CardActions>

      </Card>
    </Grid>
        )) 
    )    
  }

  const handleClickRegister = (_compid) => event => {
    event.preventDefault();
    refregister.current.handleOpen(_compid);
  }

  const handleClickFilter = () => event => {
    event.preventDefault();

    if(filtervalue.length === 0) return
    let results = companies.filter(x=>x.name.toLowerCase().includes(filtervalue.toLowerCase()))
    setFilterCompanies(results)
  }

  const handleClickClearFilter = () => event => {
    event.preventDefault();
    
    setFiltervalue("")
    setFilterCompanies(companies)
  }

  const handleChangeFilter = event => {
    event.preventDefault();
    setFiltervalue(event.target.value)
  }

  return (
    <div className={classes.root}>
       
       <main className={classes.content}>
       
       <Grid
        container
        spacing={4}
      >
        
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
        <RegisterAGM 
          ref={refregister} 
          companies={companies}
        />
          <Table size="small">
            <TableRow>
              <TableCell>
                <TextField
                  fullWidth
                  margin="dense"
                  name="filtername"
                  onChange={handleChangeFilter}
                  required
                  value={filtervalue}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HomeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
              <TableCell>
                <ButtonGroup>
                <Button
                  size="medium"
                  color="primary"
                  variant="contained"
                  onClick={handleClickFilter()}
                  //startIcon={<HttpsIcon />}
                >
                  Show
                </Button>
                <Button
                  size="medium"
                  color="primary"
                  variant="contained"
                  onClick={handleClickClearFilter()}
                >
                  Clear
                </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          </Table>
        </Grid>
        
          {displayCompanies()}
        </Grid>
        </main>
    </div>
  );
};

export default CompanyList;
