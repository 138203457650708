import React, { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useAuth } from "use-auth.js";
import firebase from "firebase/app";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Circular from 'views/Circular/Circular'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@material-ui/icons/Language';
import { 
  AppBar, 
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  whiteColor: {
    color: "white"
  },
  quoteText: {
    color: theme.palette.primary,
    //fontWeight: 300
  },
  quoteTextPrimary: {
    color: theme.palette.white,
    //fontWeight: 300
  },
  div: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  divsmall: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  divcircular: {
    padding: theme.spacing(4),
    alignItems: 'center',
  },
  dialog : {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }
}));


const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [anchorLG, setAnchorLG] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t, i18n } = useTranslation()
  const auth = useAuth()
  
  const { user } = useAuth()
  let { uid: userId } = user || {}
  const refCircular = useRef(null);

  firebase.auth().onAuthStateChanged(function(myuser) {
    
    if (myuser && myuser.emailVerified) {
      userId = myuser.uid
    }
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClickLang = event => {
    setAnchorLG(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleClickLink = (linkname) => event => {
    props.history.replace("/" + linkname)
    handleClose()
  };
  
  const handleClickLogout = event => {
    
    refCircular.current.handleOpen(true)
    auth.signout();
    handleClose()
    refCircular.current.handleOpen(false)
    
  }

  const getLoginMenu = () => {

    if(userId){

      return <div>
          <Tooltip title={t('sidebar.dashboard')} arrow={true}>
              <IconButton 
                  color="inherit"
                  onClick={handleClickLink("companies")}
                >
                 
                  <Typography
                    className={classes.quoteTextPrimary}
                    variant="h6"
                  >
                    {t('sidebar.dashboard')}
                  </Typography>
                  
              </IconButton>
          </Tooltip>  
         
          <Tooltip title={t('menu.logout')} arrow={true}>
              <IconButton 
                  color="inherit"
                  onClick={handleClickLogout}
                >
                  <Typography
                      className={classes.quoteTextPrimary}
                      variant="h6"
                    >
                    {t('menu.logout')}
                  </Typography>
              </IconButton>
            </Tooltip>
            
          </div>
    }else{

      return <div>
          <Tooltip title={t('menu.signup')} arrow={true}>
                <IconButton 
                    color="inherit"
                    onClick={handleClickLink("sign-up")}
                  >
                   
                        <Typography
                          className={classes.quoteTextPrimary}
                          variant="h6">
                            {t('menu.signup')}
                        </Typography>
                    
                </IconButton>
              </Tooltip>     
          <Tooltip title={t('menu.login')} arrow={true}>
            <IconButton 
                color="inherit"
                onClick={handleClickLink("sign-in")}
              >
                
                  <Typography
                      className={classes.quoteTextPrimary}
                      variant="h6"
                    >
                    {t('menu.login')}
                  </Typography>
                
            </IconButton>
          </Tooltip>
          
        </div> 
    }
  }

  const getLoginSmallMenu = () => {

    if(userId){

      return <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              
              <MenuItem onClick={handleClickLink("companies")}>
                    <Typography
                      className={classes.quoteText}
                      variant="h6">
                         {t('sidebar.dashboard')}
                    </Typography>
              </MenuItem>
              
              <MenuItem onClick={handleClickLogout}>
                    <Typography
                        className={classes.quoteText}
                        variant="h6"
                      >
                      {t('menu.logout')}
                    </Typography>
                  
              </MenuItem>
            </Menu>
          </div>
    }else{

      return <div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              
              <MenuItem onClick={handleClickLink("sign-up")}>
               
                    <Typography
                      className={classes.quoteText}
                      variant="h6">
                        {t('menu.signup')}
                    </Typography>
               
              </MenuItem>
              <MenuItem onClick={handleClickLink("sign-in")} >
                    
                    <Typography
                        className={classes.quoteText}
                        variant="h6"
                      >
                      {t('menu.login')}
                    </Typography>
                 
              </MenuItem>
              
            </Menu>
              </div> 
    }
  }
  
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Circular ref={refCircular} />
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/avatars/agmonline.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <div className={classes.div} >
            {getLoginMenu()}
          </div>  
        
        <div className={classes.divsmall} >
          <IconButton
            color="inherit"
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          {getLoginSmallMenu()}
        </div>
        <div className={classes.divsmall} >
          <IconButton
            color="inherit"
            onClick={handleClickLang}
          >
            <LanguageIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default withRouter(Topbar);
