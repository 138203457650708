import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import { useTranslation } from 'react-i18next'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PopupMessage from '../../../PopupMessage/PopupMessage'
import palette from "theme/palette";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  FormControl,
  InputLabel,
  IconButton,
  InputAdornment,
  Input,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: 0,
    fullWidth: true,
    //backgroundColor: "#9ee",
    display: "flex",
    wrap: "nowrap"
  },
  card: {
    padding: theme.spacing(2,2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1,1),
      height: 300
    },
    height: 450
  },
}));


const PasswordChange = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation()
  const { user } = useAuth()
  const ref = useRef(null);
  const { uid: userId } = user || {}

  const [Passwords, setPasswords] = React.useState({
    currentpassword : "",
    newpassword : "",
    confirmpassword : ""
  });

  const [showPassword, setShowPassword] = React.useState({
    showcurrent: false,
    shownew: false,
    showconfirm: false
  });

  const handleClickOpen = (msg, status) => {
    ref.current.handleOpen(msg, status);
  };
  
  const validateInput = () => {
    
    let msg = t('common.msg.blank') + ": \n"
    if(Passwords.currentpassword === ""){
      return msg + t('account.currentpassword')
    }
    if(Passwords.newpassword === ""){
      return msg + t('account.newpassword') 
    }
    if(Passwords.confirmpassword === ""){
      return msg + t('account.confirmpassword')
    }
    
    return ""
  }


  const handleClickShowCurrent = () => {
    
    setShowPassword({ 
      ...showPassword, 
      showcurrent: !showPassword.showcurrent 
    });
  };

  const handleClickShowNew = () => {
    setShowPassword({ 
      ...showPassword, 
      shownew: !showPassword.shownew 
    });
  };

  const handleClickShowConfirm = () => {
    setShowPassword({ 
      ...showPassword, 
      showconfirm: !showPassword.showconfirm 
    });
  };
  
  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleChange = event => {
   
      setPasswords({
        ...Passwords,
        [event.target.name]: event.target.value
      });
  };

  const reauthenticate = (currentPassword) => {
    var user = firebase.auth().currentUser
    var cred = firebase.auth.EmailAuthProvider.credential(
        user.email, currentPassword)
    return user.reauthenticateWithCredential(cred)
  }
  
  const changePassword = (currentPassword, newPassword) => {
    reauthenticate(currentPassword).then(() => {
      var user = firebase.auth().currentUser
      user.updatePassword(newPassword).then(() => {
        handleClickOpen("Password updated!", "success")
      }).catch((error) => { handleClickOpen(error, "error") })
    }).catch((error) => { handleClickOpen(error, "error") })
  }

  const handleClick = event => {

    event.preventDefault()

    const msg = validateInput()
    if(msg !== ""){
      handleClickOpen(msg, "error")
      return
    }

    if(Passwords.confirmpassword !== Passwords.newpassword) {
        handleClickOpen("Confirmed New Password Not Matched", "error")
        return
    }
    changePassword(Passwords.currentpassword, Passwords.newpassword)
    
  }
  
  

  return (
    <Card
      {...rest}
      className={clsx(classes.card, className)}
    >
      <PopupMessage ref={ref} />
      <form>

      <CardHeader
          title={t('account.passwordchange')}
        />
        <Divider />
        <CardContent>
          <Typography variant="body1">
              <FormControl className={clsx(classes.margin, classes.formControl)}>
              <InputLabel htmlFor="currentpassword">{t('account.currentpassword')}</InputLabel>
              <Input
                id="currentpassword"
                name="currentpassword"
                type={showPassword.showcurrent ? 'text' : 'password'}
                value={Passwords.currentpassword}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrent}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword.showcurrent ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Typography> 
              
            <Typography variant="body1">
              <FormControl className={clsx(classes.margin, classes.formControl)}>
              <InputLabel htmlFor="newpassword">{t('account.newpassword')}</InputLabel>
              <Input
                id="newpassword"
                name="newpassword"
                type={showPassword.shownew ? 'text' : 'password'}
                value={Passwords.newpassword}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNew}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword.shownew ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            </Typography>
            <Typography variant="body1">
              <FormControl className={clsx(classes.margin, classes.formControl)}>
              <InputLabel htmlFor="confirmpassword">{t('account.confirmpassword')}</InputLabel>
              <Input
                id="confirmpassword"
                name="confirmpassword"
                type={showPassword.showconfirm ? 'text' : 'password'}
                value={Passwords.confirmpassword}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirm}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword.showconfirm ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            </Typography>
            
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleClick}
            startIcon={<LockOpenIcon/>}
          >
            {t('common.reset.button')}
          </Button>
        </CardActions>
      </form>

    </Card>
  );
};

PasswordChange.propTypes = {
  className: PropTypes.string
};

export default PasswordChange;
