// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import appGlobalConfig from "appGlobalConfig"


const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  
  const sendEmailVerification = () => {
    
    let actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: appGlobalConfig.actionCodeSettingsURL, //'https://cryptolending-7b810.firebaseapp.com/sign-in',
      handleCodeInApp: false
    };

    return firebase
      .auth()
      .currentUser
      .sendEmailVerification(actionCodeSettings)
      .then(response => {
        //alert(response.user)
        return "ok";
      })
      .catch(function(error) {
        //alert(error.code)
        alert(error.message)
    });

  }  

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {

        setUser(response.user);
        return response.user;
      })
      .catch(function(error) {
        alert(error.message)
    });
  };

  const signup = (email, password) => {
    
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        return response.user;
      })
      .catch(function(error) {
        alert(error.code)
    });

  };

  
  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      });
  };

  const sendPasswordResetEmail = email => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const UpdateUserPassword = (newPassword) => {
    
    let user = firebase.auth().currentUser;
    user.updatePassword(newPassword).then(function() {
      return true;
    }).catch(function(error) {
      return false;
    });
  };

  
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  
  // Return the user object and auth methods
  return {
    user,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    UpdateUserPassword,
    sendEmailVerification
  };
}