import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import HttpsIcon from '@material-ui/icons/Https';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import clsx from 'clsx';

import {
  Card,
  Typography,
  Button,
  CardActions,
  Link
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5),
    backgroundImage: 'url(/images/automation2.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
  },
  actions: {
    padding: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center'
  }, 
  heading: {
    fontSize: theme.typography.pxToRem(20),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  quoteContainer2: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    //height: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/automation2.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    //height: '100%',
    height: '600',
    position: 'relative',
    zindex: 0, 

  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 500,
    textAlign: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
    height: 30,
    width: 30
  },
  avatar2: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 30,
    width: 30
  },
  card: {
    padding: theme.spacing(0, 0, 0),
 },
 grid: {
  padding: theme.spacing(1, 1, 1, 1),
  [theme.breakpoints.down('xs')]: {
    display: 'none'
  },
  justifyContent: 'center',
  alignItems: 'center',

},
gridsmall: {
  padding: theme.spacing(1, 1, 1, 1),
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  },
},
 
}));


const LandingHeader = props => {
  const { className } = props;
  const classes = useStyles();
  const { t } = useTranslation()
  

  return (

    <div className={classes.root}>
        <div className={classes.actions}>
            <Typography
                className={classes.quoteText}
                variant="h1"
              >
                {t('landing.header')}
              </Typography>
        </div>
        <Typography
                className={classes.quoteText}
                variant="h4"
              >
                {t('landing.header2')}
              </Typography>
        
      <div className={classes.actions}>
        <Card
            className={classes.card}
          >
           
            <Link
                  component={RouterLink}
                  to="/sign-up"
                >
              <CardActions>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    startIcon={<HttpsIcon />}
                  >
                    {t('menu.trynow')}
                  </Button>
              </CardActions>
            </Link>
          </Card>
          </div>
    </div>
    
  );
};

LandingHeader.propTypes = {
  className: PropTypes.string,
  symbols: PropTypes.array.isRequired
};

export default LandingHeader;
