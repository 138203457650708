import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import { useTranslation } from 'react-i18next'
import EditIcon from '@material-ui/icons/Edit';
import PopupMessage from '../../../PopupMessage/PopupMessage'
import storage from "firebase/storage";
import Alert from '@material-ui/lab/Alert';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  TableBody,
  Button,
  TextField,
  Checkbox,
  LinearProgress,
  DialogContentText,
  Typography,
  TableRow,
  TableCell,
  Table
} from '@material-ui/core';
import agmUtils from 'agmUtils';

const useStyles = makeStyles(theme => ({
  root: {},
  formControl: {
    margin: 0,
    fullWidth: true,
    //backgroundColor: "#9ee",
    display: "flex",
    wrap: "nowrap"
  },
  card: {
    padding: theme.spacing(2,2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1,1)
    },
    //height: 500
  },
  primaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.primary.main,
  },
  secondaryHeading: {
    color: theme.palette.success.main,
  },
  positiveNum: {
    color: theme.palette.primary.main
  },
  negativeNum: {
    color: theme.palette.warning.main
  },
}));


const AccountDetails = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation()
  const { user } = useAuth()
  let { uid: userId } = user || {}
  const [values, setValues] = useState([])
  const [imageURL, setImageURL] = useState({
    image : null,
    url : "http://via.placeholder.com/550x200"
  })
  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const ref = useRef(null);

  // firebase.auth().onAuthStateChanged(function(myuser) {
  //   if (!myuser) {
  //       props.history.replace('/sign-in')
  //       return

  //   }else{
  //     if (!myuser.emailVerified) {
  //       props.history.replace('/sign-in')
  //       return
  //     }
  //   }
  //   userId = myuser.uid 
  // });

  const handleClickOpen = (msg, status) => {
    ref.current.handleOpen(msg, status);
  };


  const validateInput = () => {
    let msg = t('common.msg.blank') + ": \n"
    if(values.length === 0)
    {
      return msg
    }   
    if(values.name === ""){
      return msg + t('account.name')
    }
    if(values.contactno === ""){
      return msg + t('Contact No') 
    }
    if(values.investorid === ""){
      return msg + t('NRIC / Investor ID')
    }
    if(values.toupload && imageURL.image !== null && imageURL.image.size > 10000000)   
    {
      return "File size not greater than 10MB"
    }
    
    return ""
  }

  useEffect(() => {
        
    if(userId){
      setLoading(true)
      const unsubscribe = firebase
          .firestore()
          .collection('agmusers')
          .doc(userId).onSnapshot(doc => {
            setValues(doc.data())
          setLoading(false)
        })
      return () => { unsubscribe() }
    }
    
  }, [userId])


  const handleChange = event => {
    
    setValues({
      ...values,
      [event.target.name]: event.target.type === 'checkbox'? event.target.checked : event.target.value
    });
  };

  const handleChangeNRIC = event => {
    
    let formatStr = agmUtils.formatStringByPattern(event.target.value)
    setValues({
      ...values,
      [event.target.name]: formatStr
    });
  };

  const handleFileChange = event =>{
    event.preventDefault();
    
    if(event.target.files[0]){
      setImageURL({
        ...imageURL,
        image : event.target.files[0],
        fileextension: getFileExtension(event.target.files[0])
      })
    }
  }

  const handleClickShow = e => {
    e.preventDefault();
    getImageURL()
    setShow(true)
  }

  const showImage = () => {

    if(!show) return ""
    
    return (
      <TableRow >
        <TableCell colSpan={2}>
          <img src={imageURL.url} alt={"Upload NRIC"} width={"100%"} height={300}/>
        </TableCell>
      </TableRow>
    )
  } 

  const getFileExtension = (fileimage) =>{

    const filename = fileimage.name || ""
    let filevar = filename.split(".")
    return filevar[1]
  }

  const handleClick = e => {
    
    e.preventDefault();

    const msg = validateInput()
    if(msg !== ""){
      handleClickOpen(msg, "error")
      return
    }

    const data = {
      "name": values.name,
      "nric": values.investorid,
      "investorid": values.investorid,
      "contactno": values.contactno
    }
    
    if(values.toupload){
      data.filetype = imageURL.fileextension
    }
    let storagepath = "images/" + userId + "."+ imageURL.fileextension
    firebase.firestore().collection("agmusers").doc(userId).set(data,{merge:true})
    .then(function () {
      
      if(!values.toupload){
        setLoading(false)
        handleClickOpen(t('common.msg.successupdate'), "success")
        return
      } 
      
      
      var storageRef = firebase.storage()
      const uploadTask = storageRef.ref(storagepath).put(imageURL.image)
      uploadTask.on("state_changed", (snapshot) => {
        setLoading(true)
      }, 
      (error) => {
        console.log(error)
        setLoading(false)
      }, 
      (complete)=> {
        saveImageName(userId)
        handleClickOpen(t('common.msg.successupdate'), "success")
        setLoading(false)
        getImageURL()
        setShow(true)
      })
    });
  };

  const getProgress= () =>{
    
    if(!loading) return ""

    return (
        <CardContent>
              <LinearProgress 
                color="secondary" 
                size={50}
                thickness={4}
              />
            </CardContent>
    )
  }

  const getImageURL = () => {

    setShow(false)
    var storageRef = firebase.storage()
    storageRef.ref("images").child(userId+"."+values.filetype).getDownloadURL()
    .then(url => {
      setImageURL({
        ...imageURL,
        "url": url
      })
    })
    .catch(err => {
      console.log('error: %j', err)
    })
    
  }
  const saveImageName = (fileimage) => {

    firebase.firestore().collection("agmusers").doc(userId).set({
      "nricname": fileimage,
      "uploaded_at":  new Date,
      "nric_uploaded":  true
    },{merge:true})
    .then(function () {
      handleClickOpen(t('common.msg.successupdate'), "success")
    });

  }

  const DisplayUserDetail = () => {

    return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
      <Table size="small">
        <TableBody>
        <TableRow>
          <TableCell>
            <Typography variant="body2" className={classes.positiveNum}>
              <span>Name as per NRIC: </span>
            </Typography>
          </TableCell>
          <TableCell>
              <Typography variant="body1" className={classes.positiveNum}>
                <TextField
                  fullWidth
                  //helperText= {t('account.name')}
                  margin="dense"
                  name="name"
                  onChange={handleChange}
                  required
                  value={values?.name}
                  variant="outlined"
                />
              </Typography>
            </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="body2" className={classes.positiveNum}>
              NRIC / Investor ID:
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1" className={classes.positiveNum}>
              <TextField
                fullWidth
                //helperText= {t('NRIC / InvestorID')}
                margin="dense"
                name="investorid"
                onChange={handleChangeNRIC}
                required
                value={values?.investorid}
                variant="outlined"
              />
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.positiveNum}>
                Contact No:
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" className={classes.positiveNum}>
                <TextField
                  fullWidth
                  //helperText= {t('Contact No')}
                  margin="dense"
                  name="contactno"
                  onChange={handleChange}
                  required
                  value={values?.contactno}
                  variant="outlined"
                />
                </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.positiveNum}>
                NRIC Provided?
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" className={classes.positiveNum}>
                <Checkbox
                  checked={values?.nric_uploaded || false}
                  disabled={true} 
                  className={classes.policyCheckbox}
                  color="primary"
                  name="attend"
                  //onChange={handleChange}
                />
                  </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.positiveNum}>
                <Checkbox
                  checked={values?.toupload || false}
                  //disabled={true} 
                  className={classes.policyCheckbox}
                  color="primary"
                  name="toupload"
                  onChange={handleChange}
                />
                  Upload NRIC?
                  </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2" className={classes.positiveNum}>
                <Button
                  variant="contained"
                  component="label"
                  size="small"
                  disabled={!values?.toupload}
                >
                  Select File
                  <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    accept=".jpg, .png, .jpeg">
                  </input>
                </Button>
                <Button
                  //fullWidth
                  disabled={values.toupload}
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={handleClickShow}
                  startIcon={<EditIcon />}
                >
                  {t('Show My NRIC')}
                </Button>
                </Typography>
              </TableCell>
          </TableRow>
        
          <TableRow>
            <TableCell>
              <Typography variant="body2" className={classes.positiveNum}>
                You selected image file name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" className={classes.positiveNum}>
                {imageURL.image?.name}  
              </Typography>
            </TableCell>
          </TableRow>
          {showImage()}
          </TableBody> 
        </Table>
          <TableRow>
            <TableCell colSpan={2}>
              <Alert severity="info">*** Note: Please upload corporate representative certificate or power of attorney for verification purpose 
                if you are corporate user</Alert>
            </TableCell>
          </TableRow>
      </Card>
    );

  }


  return (
    <Card
      {...rest}
      className={clsx(classes.card, className)}
    >
      <PopupMessage ref={ref} />
     
      <form
        noValidate
      >
        <CardHeader
          title={values?.email}
          subheader={"Type: " + values?.type?.toUpperCase()}
        />
        <Divider />
        {getProgress()}

        <CardContent>
          {DisplayUserDetail()}
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={handleClick}
            disabled={loading}
            startIcon={<EditIcon />}
          >
            {t('common.update.button')}
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
