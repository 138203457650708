import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import firebase from "firebase/app";
import { useTranslation } from 'react-i18next'
import PopupMessage from '../../../PopupMessage/PopupMessage'

import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useAuth } from "use-auth.js";


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4,4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1,1)
    }
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cell: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  overlay: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  primaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.primary.main,
  },
  secondaryHeading: {
    color: theme.palette.success.main,
  },
  positiveNum: {
    color: theme.palette.primary.main
  },
  negativeNum: {
    color: theme.palette.warning.main
  },
}));


const ProxyList = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { t } = useTranslation()
  const { user } = useAuth()
  const [proxyList, setProxyList] = React.useState([])
  const ref = useRef(null);
  const [values, setValues] = useState([])

  let { uid: userId } = user || {}
  
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(myuser) {
      if (myuser) {
        userId = myuser.uid
        const unsubscribe = firebase
          .firestore()
          .collection('agmusers')
          .doc(userId).onSnapshot(doc => {
          setValues(doc.data())
        })
        return () => { unsubscribe() }
      }
    })
  }, [])

  useEffect(() => {
    
    if (userId)
    {
      let mydate = new Date();
      mydate.setHours(0, 0, 0, 0);
      const unsubscribe = firebase
      .firestore()
      .collection('agmproxy')
      .where('proxyemail', '==', user?.email)
      .where('eventdate', '>=', mydate)
      .onSnapshot((snapshot) => {
        const newValues = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        setProxyList(newValues);
      })
      return () => { unsubscribe() }
    }
  }, [userId, user?.email])

  
  const handleClickAccept = (agmevent) => event => {
    event.preventDefault();
    
    if(!values.nric_uploaded || values.investorid === "" || values.name === ""){
      alert("Please fill in your name, NRIC and upload the NRIC first before accepting the request")
      return
    }

    agmevent.accept = true
    agmevent.userid = userId
    firebase.firestore().collection("agmproxy").doc(agmevent.id).set(agmevent)
    .then(function () {
      ref.current.handleOpen(t('common.msg.successupdate'), "info");
    })
    .catch(function(error) {
      //handleClickOpen(error, "error")
    });
    
};

  return (
    
    <div >
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <PopupMessage ref={ref} />
        <CardHeader
          title={t('You are assigned as Proxy')}
        />      
      <CardContent className={classes.content}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('From Whom')}</TableCell>
                  <TableCell>{t('AGM Event')}</TableCell>
                  <TableCell>{t('Event Date')}</TableCell>
                  <TableCell>{t('Accept Status')}</TableCell>
                  <TableCell>{t('Accept')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                  Object.keys(proxyList).map((key, i) => (
                    <Tooltip title={"You are able to change it to No by deleting the attendance under 'My AGM Event' menu. Eventhough you can't delete it once it is confirmed by organizer but you can ignore it if you do not plan to attend and vote for the event"} arrow={true}>
                    <TableRow key={key}>
                      <TableCell>
                        <Typography variant="body1" className={classes.positiveNum}>
                        {proxyList[key].fromname}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className={classes.positiveNum}>
                          {proxyList[key].eventname}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className={classes.positiveNum}>
                         {moment(new Date(proxyList[key].eventdate.seconds * 1000)).format('L')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" className={classes.positiveNum}>
                          {proxyList[key].accept?"Yes":"No"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" className={classes.positiveNum}>
                          <Button
                            color="primary"
                            size="small"
                            variant="text"
                            disabled={proxyList[key].accept}
                            onClick={handleClickAccept(proxyList[key])}
                          >
                            Accept <ArrowRightIcon />
                          </Button>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    </Tooltip>
                    ))
                }
              </TableBody>
            </Table>
      </CardContent>
    </Card>
    </div>
  );
};

ProxyList.propTypes = {
  className: PropTypes.string
};

export default ProxyList;
