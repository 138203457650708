import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import palette from "theme/palette";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactExport from "react-export-excel";
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import PopupMessage from '../PopupMessage/PopupMessage'
import { useTranslation } from 'react-i18next'
import storage from "firebase/storage";
import agmUtils  from "agmUtils"

import {
  Card,
  CardContent,
  Button,
  Typography,
  Table,
  TableRow,
  TableCell,
  TextField,
  Checkbox,
  Grid,
  CardActions,
  TableHead,
  TableBody,
  CardHeader,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  ButtonGroup
} from '@material-ui/core';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1,1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1,1)
    },
  },
  grid: {
    alignItems:"stretch"
 },
 texttitle: {
   color: palette.text.white
 },
 drawer: {
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
},
tableCell: {
  padding: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
},
drawerPaper: {
  width: drawerWidth,
},
menuButton: {
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
},
toolbar: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  padding: theme.spacing(3,3),
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(1,1)
  },
  minHeight: 600
},
primaryHeading: {
  fontSize: theme.typography.pxToRem(15),
  color: theme.palette.primary.main,
},
secondaryHeading: {
  color: theme.palette.success.main,
},
positiveNum: {
  color: theme.palette.primary.main
},
negativeNum: {
  color: theme.palette.warning.main
},
}));

const staticimage = "http://via.placeholder.com/550x200"

const VerifyUsers  = props => {
  const classes = useStyles();
  const ref = useRef(null);
  const { user } = useAuth()
  let { uid: userId } = user || {}
  const [agmEvent, setAgmEvent] = useState([])
  const [agmEvents, setAgmEvents] = useState([])
  const [agmSelectedEvent, setAgmSelectedEvent] = useState([])
  const [attendances, setAttendances] = useState([])
  const [attendancesProxy, setAttendancesProxy] = useState([])
  const [attendancesNonProxy, setAttendancesNonProxy] = useState([])
  const [dataset, setDataset] = useState([])
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const [values, setValues] = useState([])
  const [imageURL, setImageURL] = useState({
    image : null,
    url : staticimage
  })
  const [msgStatus, setMsgStatus] = React.useState("");
  const { t } = useTranslation()
  const [companyid, setCompanyid] = React.useState("");

  firebase.auth().onAuthStateChanged(function(myuser) {
    //console.log(myuser)
    if (!myuser) {
        props.history.replace('/sign-in')
        return

    }else{
      if (!myuser.emailVerified) {
        props.history.replace('/sign-in')
        return
      }
    }
    userId = myuser.uid 
  });
  
  
  useEffect(() => {
      
    if(userId){

      const unsubscribe = firebase
          .firestore()
          .collection('agmusers')
          .doc(userId).onSnapshot(doc => {
          setValues(doc.data())
          let companyid = doc.data().plcid
          let usertype = doc.data().type
          setCompanyid(companyid)
          if (usertype !== "admin") {
            props.history.replace('/companies')
            return
          }
        })
      return () => { unsubscribe() }
    }
    
  }, [userId])

  useEffect(() => {

      if(userId === undefined) return
      let mydate = new Date();
      mydate.setHours(0, 0, 0, 0);
      const unsubscribe = firebase
      .firestore()
      .collection('agmevents')
      .where('userid', '==', userId)
      .where('eventdate', '>=', mydate)
      .onSnapshot((snapshot) => {
        const newValues = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        setAgmEvents(newValues);
      })
      return () => { unsubscribe() }

  }, [userId])


  const GetData = () => {
    
    if(agmSelectedEvent.eventid === undefined) return
    const unsubscribe = firebase
    .firestore()
    .collection('agmuserattendance')
    .where('eventid', '==', agmSelectedEvent.eventid)
    .onSnapshot((snapshot) => {
      const newValues = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
      setAttendances(newValues);
      splitProxyAttendance(newValues)
      fillinDataset(newValues)
      
    })
    return () => { unsubscribe() }
  }

  const splitProxyAttendance = (allatt) => {
    
    let nonProxyAttenances = allatt.filter(x=>x.proxyuser === false)
    setAttendancesNonProxy(nonProxyAttenances)

    let proxyAttenances = allatt.filter(x=>x.proxyuser === true)
    setAttendancesProxy(proxyAttenances)
  }

  const fillinDataset = (allatt) => {

    let attendees = []
      allatt.forEach(att => {
        attendees.push({
          "username" : att.username,
          "email" :  att.email+" ,",
          "nric" :  att.nric,
          "shares" : att.shares,
          "status" : att.status,
          "proxyuser" : att.proxyuser,
          "fromname" : att.fromname,
          "fromnric" : att.fromnric
        })

        setValues({
          ...values,
          ["shares"+att.id]: att.shares
        });

      });
      setDataset(attendees)
  }

  const handleMessageOpen = (msg, status, url) => {
    ref.current.handleShowImage(true, url);
    ref.current.handleOpen(msg, status);
    setImageURL.url = staticimage
  }

  const handleChangeRadio = (att, type) => event => {

    if(event.target.value === "ok") {
        att.status = "confirmed" 
    }else if(event.target.value === "reject") {
        att.status = "reject" 
    }
    console.log(type)
    if(type === "nonproxy"){
      rejectProxySharesToo(att, event.target.value)
    }
  };


  const handleChange = (att) => event => {

    setValues({
      ...values,
      [event.target.name]: event.target.type === 'checkbox'? event.target.checked : event.target.value
    });

    if(event.target.checked) {
      att.status = "confirmed" 
    }else{
      att.status = "unconfirmed" 
    }
  };

  const rejectProxySharesToo = (att, parentOutput) => {

    let proxyFound = attendancesProxy.find(x=>x.email === att.proxyemail)
    if(proxyFound === undefined) return

    console.log(proxyFound)
    proxyFound.status = att.status
    setValues({
      ...values,
      ["verifyproxy" + proxyFound.id]: parentOutput
    });
  }

  const updateProxySharesToo = (att) => {

    let proxyFound = attendancesProxy.find(x=>x.email === att.proxyemail)
    if(proxyFound === undefined) return
    
    proxyFound.shares = att.shares
    proxyFound.prevShares = att.prevShares
    setValues({
      ...values,
      ["shares"+proxyFound.id]: proxyFound.shares
    });
  }
  
  const handleChangeShares = (att) => event => {

    att.prevShares = att.shares
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    att.shares = parseFloat(event.target.value)
    updateProxySharesToo(att)

  };

  const handleClickShow = (att) => e => {
    e.preventDefault();
    getImageURL(att)
  }

 
  const handleClickShowAssignee = (att) => e => {
    e.preventDefault();
    let content = "<p>Name: "+ att.fromname + "</p><p> NRIC: " + att.fromnric + "</p>"
    ref.current.handleOpen(content, "info");
    
  }

  const getImageURL = (att) => {

    var storageRef = firebase.storage()
    storageRef.ref("images").child(att.userid+"."+att.filetype).getDownloadURL()
    .then(url => {
      setImageURL({
        ...imageURL,
        "url": url
      })
      let content = att.username + ": " + att.nric
      handleMessageOpen(content, "info", url)
    })
    .catch(err => {
      console.log('error: %j', err)
    })
  }

  
  const handleClickVerifyUpdate = () => event => {
    event.preventDefault();
    
    console.log(attendances)
    setMsgStatus("")
    attendances.forEach(att => {
      if(!att.voted){
        firebase.firestore().collection("agmuserattendance").doc(att.id).update(att)
          .then(function () {
            ref.current.handleOpen(t('common.msg.successupdate'), "info");
          })
          .catch(function(error) {
            ref.current.handleOpen(error, "error");
        });
      }
    });
  }

  
  const getIsProxy = (type) => {
    return type === "nonproxy"?  attendancesNonProxy: attendancesProxy
  }

  const getAssigneeColumn = (type) => {
    
    if(type === "nonproxy")  return ""

    return (
      <TableCell>
        View Assignee
      </TableCell>
    )
  }

  const getAssigneeColumnButton = (type, att) => {
    
    if(type === "nonproxy")  return ""

    return (
      <TableCell>
          <Button
            size={"small"}
            color="primary"
            variant="contained"
            onClick={handleClickShowAssignee(att)}
            //startIcon={<EditIcon />}
          >
          Assignee
        </Button>
      </TableCell>
    )
  }

  const handleGetProxy = () => event => {
    event.preventDefault();

  }

  const displayAttendance = (type) => {
    
    if(attendances === undefined) return ""

    let attendanceTypes = getIsProxy(type)
    
    return (
      <Table size="small">
        <TableHead>
            <TableRow>
              <TableCell>
                User Name
              </TableCell>
              <TableCell>
                NRIC
              </TableCell>
              <TableCell>
                Email
              </TableCell>
              <TableCell>
                Shares
              </TableCell>
              <TableCell className={classes.tableCell}>
                Verified
              </TableCell>
              <TableCell className={classes.tableCell}>
                Attend?
              </TableCell>
              <TableCell className={classes.tableCell}>
                Voted?
              </TableCell>
              <TableCell>
                View NRIC
              </TableCell>
                { getAssigneeColumn(type) }
              <TableCell>
                
              </TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
        {
            attendanceTypes.map(att => (
                  <TableRow key={att.id} hover onClick={handleGetProxy(att)}>
                    <TableCell>
                      <Typography variant="body2" className={classes.positiveNum}>
                        {att.username}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" className={classes.positiveNum}>
                        {att.nric}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" className={classes.positiveNum}>
                        {att.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                    <TextField 
                      //fullWidth
                      name={"shares" + att.id} 
                      //label="Event Name" 
                      margin="dense" 
                      variant="outlined"
                      value={values["shares" + att.id] }
                      onChange={handleChangeShares(att)}
                      disabled={att.voted || att.proxyuser}
                      />
                      {/* <Typography variant="body2" className={classes.positiveNum}>
                        {agmUtils.formatNumber(att.shares)}
                      </Typography> */}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="body2" className={att.status?.toUpperCase() === "CONFIRMED" ? classes.positiveNum: classes.negativeNum}>
                        {att.status?.toUpperCase()}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="body2" className={classes.positiveNum}>
                      <Checkbox
                          checked={att.attend || false}
                          className={classes.policyCheckbox}
                          disabled={true}
                          color="primary"
                          name={"attend" + att.id}
                          onChange={handleChange(att)}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="body2" className={classes.positiveNum}>
                      <Checkbox
                          checked={att.voted || false}
                          className={classes.policyCheckbox}
                          disabled={true}
                          color="primary"
                          name={"voted" + att.id}
                          onChange={handleChange(att)}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell>
                        <Button
                          size={"small"}
                          color="primary"
                          variant="contained"
                          onClick={handleClickShow(att)}
                        >
                          {'Show'}
                        </Button>
                    </TableCell>
                    {
                      getAssigneeColumnButton(type, att)
                    }
                    <TableCell>
                      <Typography variant="body2" className={classes.positiveNum}>

                      <FormControl component="fieldset">
                        <RadioGroup aria-label="verify" name="verify" value={values["verify" + type + att.id]} 
                          
                          onChange={handleChangeRadio(att, type)}>
                          <FormControlLabel value="ok" disabled={att.voted} control={<Radio />} label="OK" />
                          <FormControlLabel value="reject" disabled={att.voted} control={<Radio />} label="Reject" />
                        </RadioGroup>
                      </FormControl>
                        </Typography>
                  </TableCell>
                  </TableRow>
            )) 
        }
      </TableBody>
      </Table>
    )
  }

  const handleClickFilter = () => event => {
   
    let selected = agmEvents.find(x=>x.eventid === agmSelectedEvent.eventid)
    setAgmEvent(selected)

    GetData()
  }

  const getSupportedEvents = () => {
    
    const events = []
    agmEvents.forEach(ev => {
      events.push({
        name: ev.eventname,
        value: ev.eventid
      })
    });
    return events
  }

  return (
    <div className={classes.root}>
       <main className={classes.content}>
       <PopupMessage ref={ref} />
       <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
        
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Autocomplete
                    id="eventid"
                    size="small"
                    name="eventid"
                    options={getSupportedEvents()}
                    getOptionLabel={option => option.name}
                    onChange={(event, newValue) => {
                      setAgmSelectedEvent({
                        eventid: newValue == null? "" : newValue.value
                      });
                    }}
                    renderInput={params => <TextField {...params} fullWidth label="Event Name" margin="dense" variant="outlined" />}
                  />
                </TableCell>
                <TableCell>
                  <ButtonGroup>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={handleClickFilter()}
                    //startIcon={<HttpsIcon />}
                  >
                    Filter
                  </Button>
                  <ExcelFile element={
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      >Export To Excel</Button>}>
                    <ExcelSheet data={dataset} name="userlist">
                        <ExcelColumn label="User Name" value="username"/>
                        <ExcelColumn label="NRIC" value="nric"/>
                        <ExcelColumn label="Email" value="email"/>
                        <ExcelColumn label="Shares" value="shares"/>
                        <ExcelColumn label="Status" value="status"/>
                        <ExcelColumn label="Proxy User" value="proxyuser"/>
                        <ExcelColumn label="Assignor" value="fromname"/>
                        <ExcelColumn label="Assignor NRIC" value="fromnric"/>
                    </ExcelSheet>
                </ExcelFile>
                </ButtonGroup>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
        <Card
        >
          <CardHeader
            className={classes.primaryHeading}
            title={"Non Proxy Attendees"}
            titleTypographyProps={{variant:'h5' }}
            subheaderTypographyProps={{variant:'body1' }}
          />
          <CardContent>
            {displayAttendance("nonproxy")}
          </CardContent>
          <CardContent>
             <Typography variant="body1" className={classes.positiveNum}>
              {msgStatus}
              </Typography>
          </CardContent>    
          <CardHeader
            className={classes.primaryHeading}
            title={"Proxy Attendees"}
            titleTypographyProps={{variant:'h5' }}
            subheaderTypographyProps={{variant:'body1' }}
          />
            <CardContent>
              {displayAttendance("proxy")}
            </CardContent>
            <CardActions>
              <Button
                    fullWidth
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={handleClickVerifyUpdate()}
                    //startIcon={<HttpsIcon />}
                  >
                    Verify Update
                  </Button>
            </CardActions>   
          <CardContent>
             <Typography variant="body1" className={classes.positiveNum}>
              {msgStatus}
              </Typography>
          </CardContent>     
        </Card>
        </Grid>

      </Grid>
     </main>
    </div>
  );
};

export default VerifyUsers;
