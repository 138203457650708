import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import palette from "theme/palette";
import Autocomplete from '@material-ui/lab/Autocomplete';
import firebase from "firebase/app";
import { useAuth } from "use-auth.js";
import PopupMessage from '../PopupMessage/PopupMessage'
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';

import {
  Card,
  CardContent,
  Button,
  Typography,
  Table,
  TableRow,
  TableCell,
  TextField,
  Grid,
  CardActions,
  TableBody,
  CardHeader
} from '@material-ui/core';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1,1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1,1)
    },
  },
  grid: {
    alignItems:"stretch"
 },
 texttitle: {
   color: palette.text.white
 },
 drawer: {
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
},
tableCell: {
  padding: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
},
drawerPaper: {
  width: drawerWidth,
},
menuButton: {
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
},
toolbar: theme.mixins.toolbar,
content: {
  flexGrow: 1,
  padding: theme.spacing(3,3),
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(1,1)
  },
  minHeight: 600
},
card: {
  padding: theme.spacing(2,2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1,1)
  },
  minHeight: 450,
  maxHeight: 750
},
primaryHeading: {
  fontSize: theme.typography.pxToRem(15),
  color: theme.palette.primary.main,
},
secondaryHeading: {
  color: theme.palette.success.main,
},
positiveNum: {
  color: theme.palette.primary.main
},
negativeNum: {
  color: theme.palette.warning.main
},
}));

const SetupEvents  = props => {
  const classes = useStyles();
  const ref = useRef(null);
  const { user } = useAuth()
  let { uid: userId } = user || {}
  const [agmEvent, setAgmEvent] = useState([])
  const [agmEvents, setAgmEvents] = useState([])
  const [agmSelectedEvent, setAgmSelectedEvent] = useState([])
  const { t } = useTranslation()
  const [votingTimeOut, setVotingTimeOut] = useState(new Date());

  firebase.auth().onAuthStateChanged(function(myuser) {
    if (!myuser) {
        props.history.replace('/sign-in')
        return

    }else{
      if (!myuser.emailVerified) {
        props.history.replace('/sign-in')
        return
      }
    }
    userId = myuser.uid 
  });
  
  
  useEffect(() => {

      if(userId === undefined) return
      let mydate = new Date();
      mydate.setHours(0, 0, 0, 0);
      const unsubscribe = firebase
      .firestore()
      .collection('agmevents')
      .where('userid', '==', userId)
      .where('eventdate', '>=', mydate)
      .onSnapshot((snapshot) => {
        const newValues = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        setAgmEvents(newValues);
      })
      return () => { unsubscribe() }

  }, [userId])


  const handleClicUpdate = () => event => {
      event.preventDefault();
    
      agmEvent.votingTimeOut = votingTimeOut

      firebase.firestore().collection("agmevents").doc(agmEvent.id).update(agmEvent)
        .then(function () {
          ref.current.handleOpen(t('common.msg.successupdate'), "info");
        })
        .catch(function(error) {
          ref.current.handleOpen(error, "error");
      });
  };


  const displayAgmEvents = () => {
    
    if(agmEvent === undefined) return ""
    if(agmEvent.length === 0) return ""

    return (
        <Card className={classes.card}>
          <CardHeader
            className={classes.primaryHeading}
            //subheader= {comp.registration}
            title={agmEvent?.eventname}
            //titleTypographyProps={{variant:'h5' }}
            subheaderTypographyProps={{variant:'body1' }}
          />
         
            <CardContent>
              <Table size="small">
                <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Event Date:</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {moment(new Date(agmEvent?.eventdate.seconds * 1000)).format('ll')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Voting Timeout Set</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography variant="body1" className={classes.positiveNum}>
                      {agmEvent.votingTimeOut=== undefined? "" :
                       moment(new Date(agmEvent?.votingTimeOut?.seconds * 1000)).format('lll')}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Set Voting Timeout Now</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <DateTimePicker
                    onChange={setVotingTimeOut}
                    value={votingTimeOut}
                  />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Company Name</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {agmEvent?.companyname}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Company Address</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {agmEvent?.companyaddress}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Company Email</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {agmEvent?.companyemail}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" className={classes.positiveNum}>
                      <span>Phone No</span>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" className={classes.positiveNum}>
                      {agmEvent?.companytelno}
                    </Typography>
                  </TableCell>
                </TableRow>
                
                </TableBody>
              </Table>
          </CardContent>
          <CardContent>
            <Typography variant="body1" className={classes.negativeNum}>
              {/* Note: Admin will send you webex link for registration once verification is done. <br/>
              Please check your mailbox.  */}
            </Typography>
          </CardContent>

          <CardActions>
              <Button
                fullWidth
                size="medium"
                color="primary"
                variant="contained"
                //disabled={att.assignproxy || !att.attend || att.voted || att.status === "unconfirmed"}
                onClick={handleClicUpdate()}
                //startIcon={<HttpsIcon />}
              >
                Update
              </Button>
          </CardActions>
      </Card>
    )    
  }


  const handleClickFilter = () => event => {
   
    let selected = agmEvents.find(x=>x.eventid === agmSelectedEvent.eventid)
    setAgmEvent(selected)
  }

  const getSupportedEvents = () => {
    
    const events = []
    agmEvents.forEach(ev => {
      events.push({
        name: ev.eventname,
        value: ev.eventid
      })
    });
    return events
  }

  return (
    <div className={classes.root}>
       <main className={classes.content}>
       <PopupMessage ref={ref} />
       <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
        
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Autocomplete
                    id="eventid"
                    size="small"
                    name="eventid"
                    options={getSupportedEvents()}
                    getOptionLabel={option => option.name}
                    onChange={(event, newValue) => {
                      setAgmSelectedEvent({
                        eventid: newValue == null? "" : newValue.value
                      });
                    }}
                    renderInput={params => <TextField {...params} fullWidth label="Event Name" margin="dense" variant="outlined" />}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={handleClickFilter()}
                    //startIcon={<HttpsIcon />}
                  >
                    Show
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        > 
              {displayAgmEvents()}
        
        </Grid>
      </Grid>
     </main>
    </div>
  );
};

export default SetupEvents;
